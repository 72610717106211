import { Component, OnInit } from '@angular/core';
import {AngularFireStorage} from "@angular/fire/storage";
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { NO_EMPTY_AND_SPACES_PATTERN, NO_EMPTY_PATTERN } from 'src/app/shared/constants/pattern-constants';
// import  {clabe} from "clabe-validator";
declare var require: any

const clabe = require('clabe-validator');

import {finalize} from "rxjs/operators";
import { ProfileService } from 'src/app/shared/services/profile.service';
import { ProviderUser } from 'src/app/models/Provider';
import { EarnPaymentService } from 'src/app/shared/services/earn-payments.service';
@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css']
})
export class PersonalComponent implements OnInit {
  

  uploading = {
    ineFrontal: false,
    ineDetras: false,
  }

  doc: any;
  createForm: UntypedFormGroup;
  user: ProviderUser;
  processing:boolean=false;
  constructor(private earnP: EarnPaymentService,private toastS: ToastrService,private storage: AngularFireStorage, private profileS: ProfileService) {
    this.createForm = this.formGroup();
   }

  ngOnInit(): void {  
    this.fetchDocs();
  }


  onUploadIneFrontal(e){
    const file = e.target.files[0];
    const uui = Math.random().toString(36).substring(2);
    const filePath = `provider/files/identify_${uui}`;
    const ref = this.storage.ref(filePath);
    this.uploading.ineFrontal = true;
    const task = this.storage.upload(filePath,file).then(snapshot=> {
      snapshot.ref.getDownloadURL().then(url => {
        const imageIneFrontalUrl =  url;
        this.profileS.updateIneFrontal(imageIneFrontalUrl).then(res=>{
          this.toastS.success("Subida correctamente.");
          this.uploading.ineFrontal = false;
          this.fetchDocs();
        })
      })
    });
  }


  onUploadIneAtras(e){
    const file = e.target.files[0];
    const uui = Math.random().toString(36).substring(2);
    const filePath = `provider/files/identify_${uui}`;
    const ref = this.storage.ref(filePath);
    this.uploading.ineDetras = true;
    const task = this.storage.upload(filePath,file).then(snapshot=> {
      snapshot.ref.getDownloadURL().then(url => {
        const imageIneDetrasUrl =  url;
        this.profileS.updateIneDetras(imageIneDetrasUrl).then(res=>{
          this.uploading.ineDetras = true;
          this.toastS.success("Subida correctamente.");
          this.fetchDocs();
        })
      })
    });
  }

  fetchDocs(){
    this.profileS.getDocsProvider().get()
                 .then(data => {
                    this.doc = data.data();
                    console.log(this.doc);
                 })
                 .catch(error=> console.log(error))
  }




  formGroup() {
    return new UntypedFormGroup({
        clabe: new UntypedFormControl('', [Validators.required, Validators.pattern(NO_EMPTY_AND_SPACES_PATTERN)]),
        legal_name: new UntypedFormControl('', [Validators.required, Validators.pattern(NO_EMPTY_PATTERN)]),
        name: new UntypedFormControl('',[Validators.required, Validators.pattern(NO_EMPTY_PATTERN)]),
        rfc: new UntypedFormControl('',[Validators.required, Validators.pattern(NO_EMPTY_AND_SPACES_PATTERN)]),
        // checked: new FormControl('', [Validators.required]),
    });
  }

  get clabe() { return this.createForm.get('clabe') }
  get legal_name() { return this.createForm.get('legal_name') }
  get name() { return this.createForm.get('name') }
  get rfc() { return this.createForm.get('rfc') }


  onSave(user:ProviderUser){
    if (this.createForm.valid) {
      var accept:any = document.getElementById('checkedTerms');
      if(accept.checked){
          const _clabe = this.clabe.value;
          const legal_name = this.legal_name.value;
          const name = this.name.value;
          const rfc = this.rfc.value;

          //Save data on sanbox:
          //this.saveInformation("jhj42j42j",name,_clabe);

          const clabeCheck = clabe.validate(_clabe);
          //Save data on production:
          if (clabeCheck.ok) {
              this.saveInformation( user.providerId,name,_clabe);
          }else{
            this.toastS.error("CLABE interbancaria invalida");
          }






      }else{
        this.toastS.warning('Debes aceptar los terminos y condiciones');
      }
    } else {
        this.toastS.show('Verifique la información.');
    }
  }



  onOpenModal(){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.add('active');
    popup.classList.add('active');
   
  }
  
  cerrarModal(){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.remove('active');
    popup.classList.remove('active');
  }



  saveInformation(_provider,_name,_clabe){
    this.processing=true;
    this.earnP.createProviderPayment(_provider,_name,_clabe)
              .subscribe(res=>{
                this.processing=false;
                this.toastS.success("Guardado correctamente.");
              },error =>{this.processing=false, this.toastS.error("Error al guardar la información.")})
  }


  getUser(){
    this.profileS.getDataProvider().then(res =>{
      this.user = res.data() as ProviderUser;
    })
  }


}
