import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import 'firebase/firestore';

import firebase from 'firebase/app';
@Injectable({
    providedIn: 'root'
})
export class OrderService {

    constructor( private firestore: AngularFirestore) { }

    fetchOrders() {
        const providerId = this.currentUserId;
        return this.firestore.collection('reservations', ref =>
            ref.where('providerId', '==', providerId)
                .where('isActive', '==', true)
                .where('dispatching', '==', false)
                .orderBy('createdAt', 'desc')
        ).snapshotChanges()
    }

     // Returns current user UID
     get currentUserId(): string {
        return firebase.auth().currentUser.uid;
    }

 
    //Obtener reservaciones pagadas con tarjeta 

    //OBTENDO LAS GANANCIAS DE LAS RESERVACIONES PAGADAS CON TARJETA Y FINALIZADAS

    fetchOrdersPaidWithCard(last_reservation_reference: number) {
        const providerId = this.currentUserId;
        if (last_reservation_reference == null || last_reservation_reference == undefined) {
            return this.firestore.collection('reservations', ref =>
                ref.where('providerId', '==', providerId)
                    .where('status', '==', 'Finalizado')
                    .where('paymentMethod', '==', 'card')
                    .orderBy("createdAt", 'desc')
            ).snapshotChanges()
        } else {
            return this.firestore.collection('reservations', ref =>
                ref.where('providerId', '==', providerId)
                    .where('status', '==', 'Finalizado')
                    .where('paymentMethod', '==', 'card')
                    .orderBy("createdAt", 'desc')
                    .endBefore(last_reservation_reference)
            ).snapshotChanges()
        }

    }

    //

    //First data 8 ITEMS ::: Reservations
    fetchOrdersCompleted() {
        const providerId = this.currentUserId;
        return this.firestore.collection('reservations', ref =>
            ref.where('providerId', '==', providerId)
                .where('status', '==', 'Finalizado')
                .orderBy("createdAt", 'desc')
                .limit(8)
        ).snapshotChanges()
    }

    //More data next two items then end. ::Reservations
    fetchNextOrdersCompleted(lastId: any) {
        const providerId = this.currentUserId;
        return this.firestore.collection('reservations', ref =>
            ref.where('providerId', '==', providerId)
                .where('status', '==', 'Finalizado')
                .orderBy('createdAt', 'desc')
                .limit(4)
                .startAfter(lastId)
        ).snapshotChanges()
    }

    fetchOrder(reservationId: string) {
        return firebase.firestore()
            .collection("reservations")
            .doc(reservationId)
    }

    fetchReservationsOnOrogress() {
        const providerId = this.currentUserId;
        return this.firestore.collection('reservations', ref =>
            ref.where('providerId', '==', providerId)
                .where('isActive', '==', true)
                .where('dispatching', '==', true)
                .orderBy('createdAt', 'desc')
        ).snapshotChanges()
    }

    //Accept reservatión
    acceptReservation(reservationId: string) {
        return this.firestore.collection('reservations').doc(reservationId).update({
            dispatching: true,
            status: 'En progreso'
        });
    }

    updateToNewDate(dateClient: number, reservationId: string) {
        return this.firestore.collection('reservations').doc(reservationId).update({
            dateClient: dateClient,
        });
    }

    //End reservation
    endWork(reservationId: string) {
        return this.firestore.collection('reservations').doc(reservationId).update({
            dispatching: false,
            status: 'Finalizado',
            isActive: false
        });
    }

    //Reject reservatión
    rejectReservation(reservationId: string) {
        return this.firestore.collection('reservations').doc(reservationId).update({
            isActive: false
        });
    }

    chageToOnProgress(reservationId: string) {
        return this.firestore.collection('reservations').doc(reservationId).update({
            status: 'En progreso'
        });
    }

    chageToReject(reservationId: string) {
        return this.firestore.collection('reservations').doc(reservationId).update({
            status: 'Cancelado'
        });
    }

    addDeliverIdToReservation(reservationId: string, deliverId: string) {
        return this.firestore.collection('reservations').doc(reservationId).update({
            deliveryManId: deliverId
        });
    }

    //Just on case of tracking !! Not then for Seekmefy :::::::::::::
    dissableDeliver(deliverId: string) {
        return this.firestore.collection('colaborators').doc(deliverId).update({
            available: false
        });
    }

}