import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CustumerI, SubscriptionI, DataCustumerI, PaymentI } from 'src/app/models/Payment';
import { environment } from '../../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';

import { ChargeSubscriptionI } from 'src/app/models/Charge';
import firebase from 'firebase/app';
import 'firebase/firestore';
@Injectable({
    providedIn: 'root'
})
export class EarnPaymentService {

    URI_BASE_CHARGE: string;

    constructor(private firestore: AngularFirestore, private httpClient: HttpClient) { }
    
    // Returns current user UID
    get currentUserId(): string {
        return firebase.auth().currentUser.uid;
    }

    createProvider(custumer: CustumerI) {
        const URI: string = environment.URI_BASE;
        return this.httpClient.post(URI + '/business/new', custumer);
    }

    getPayments(){
        // const provider_id:string =  'lclvZ3h5thhPr53OLq5t'; //mode sanbox
        const provider_id = this.currentUserId;//Mode production 
        const URI: string = environment.URI_BASE;
        return this.httpClient.post(URI+'/payments/all',{ provider_id });
    }


    fetchTransatiosProcesed(){
        const provider_id:string =  'lclvZ3h5thhPr53OLq5t'; //mode sanbox
        const status = "Transferido";
        // const provider_id = this.currentUserId;//Mode production 
        const URI: string = environment.URI_BASE;
        return this.httpClient.post(URI+'/transactions/processed',{ provider_id,status });       
    }


    createProviderPayment(provider_id:string, name:string, clabe:string){
        const URI: string = environment.URI_BASE;
        return this.httpClient.post(URI+'/providers/add',{ provider_id,name,clabe });  
    }

}