import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ContactaInformationI, DocProviderI, OrganizationI, ScheduleI } from 'src/app/models/Provider';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
// import { firestore } from 'firebase';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(firebaseAuth: AngularFireAuth,private httpClient: HttpClient, private firestore: AngularFirestore) { 
        
    }

 

    // Returns current user UID
    get currentUserId(): string {
        return firebase.auth().currentUser.uid;
    }

    get currentUser(): any {
        return firebase.auth().currentUser;
    }


    
    getProfile() {
        const providerId = this.currentUserId;
        return this.firestore.collection("providers").doc(providerId).snapshotChanges();
    }

    getClient(clientId) {
        return firebase.firestore().collection('users').doc(clientId)
    }

    getColaborator(colaboratorId: string) {
        return this.firestore.collection("colaborators").doc(colaboratorId).snapshotChanges();
    }

    getProvider() {
        const providerId = this.currentUserId;
        return firebase.firestore().collection('providers').doc(providerId);
    }

    getDataProvider(){
        const providerId = this.currentUserId;
        return firebase.firestore().collection('providers').doc(providerId).get();
    }

    getDocsProvider() {
        const providerId = this.currentUserId;
        return firebase.firestore().collection('docsprovider').doc(providerId);
    }

    createDocProvider(providerId:string){
        const doc : DocProviderI = {
            backCredential :{
                imageUrl:'',
                uploaded:false
            },
            frontalCredential : {
                imageUrl:'',
                uploaded:false
            }
        }
        return this.firestore.collection('docsprovider').doc(providerId).set(doc);
    }

    //Actualizar  informacion importante de PROVEEDOR 
    updateIneFrontal(urlImage: string) {
        const providerId = this.currentUserId;
        return this.firestore.collection('docsprovider').doc(providerId).update(
            { 
                "frontalCredential.imageUrl": urlImage,
                "frontalCredential.uploaded": true,
            }
        )
    }

    updateIneDetras(urlImage: string) {
        const providerId = this.currentUserId;
        return this.firestore.collection('docsprovider').doc(providerId).update(
            { 
                "backCredential.imageUrl": urlImage,
                "backCredential.uploaded": true 
            }
        )
    }



    updatePhone(newPhoneNumber: string) {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            { "phoneNumber": newPhoneNumber }
        )
    }

    updatePersonalData(contactInformaction: ContactaInformationI) {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            { "contactInformaction":  contactInformaction }
        )
    }

    updateClabe(clabe: string) {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            { "clabe": clabe }
        )
    }

    //For check if kep subscriptión
    enableProvider() {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            { "isActive": true,"suspendedAccount": false }
        )
    }

    //For check if kep subscriptión
    disableProvider() {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            { 
                "organization.range": 0,
                "suspendedAccount": true
            }
        )
    }

    enableProviderRange() {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            { "organization.range": 50 }
        )
    }

    updateEmail(email: string) {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            { "email": email }
        )
    }

    storeOrganization(organization: OrganizationI) {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            { "organization": organization }
        )
    }

    //Añadiendo etiquetas
    updateTag(tag: string) {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            {
                "tags": firebase.firestore.FieldValue.arrayUnion(tag)
            }
        )
    }

    //eliminando etiquetas
    removeTag(tag: string) {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            {
                "tags": firebase.firestore.FieldValue.arrayRemove(tag)
            }
        )
    }

    updateLocationOrganization(address: string, lat: number, lng: number) {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            {
                "organization.adress": address,
                "organization.location.latitud": lat,
                "organization.location.longitude": lng,
            }
        )
    }

    updateAllowCardPayments(allowCardPayments: boolean) {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            {
                "organization.allowCardPayments": allowCardPayments
            }
        )
    }

    updateProfilePhotoUr(imageUrl: string) {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            { "photoUrl": imageUrl }
        )
    }

    updateFCMToken(fcmToken: string) {
        const providerId = this.currentUserId;
        return this.firestore.collection('providers').doc(providerId).update(
            { "fcmToken": fcmToken }
        )
    }

    addSchedule(schedule: ScheduleI) {
        const providerId = this.currentUserId;
        firebase.firestore().collection('providers').doc(providerId).update({
            "organization.schedule": firebase.firestore.FieldValue.arrayUnion(schedule)
        })
    }

    getCompanyInformation() {
        const providerId = this.currentUserId;
        return this.firestore.collection("providers").doc(providerId).snapshotChanges();
    }
    // Colaborator
    getColaborators() {
        const providerId = this.currentUserId;
        return this.firestore.collection('colaborators', ref =>
            ref.where('providerId', '==', providerId)
                .where("available", "==", true)).snapshotChanges()
    }

    //INHABILITAR COLABORADOR
    dissable(colaboratorId) {
        //return this.firestore.collection('colaborators').doc(colaboratorId).delete();
        return this.firestore.collection('colaborators').doc(colaboratorId).update(
            { "available": false }
        )
    }

    disableUser(uid: string): Observable<any> {
        return this.httpClient.post("http://localhost:4000/firebase/admin/user-disable", { uid: uid })
    }

    //Informacion para facturacion

    getLegalData() {
        const providerId = this.currentUserId;
        return firebase.firestore().collection("providers")
            .doc(providerId)
            .collection('legal')
            .doc('legal_data').get()
    }

    updateLegalData(legal_name: string, rfc: string) {
        const providerId = this.currentUserId;
        return firebase.firestore().collection("providers")
            .doc(providerId)
            .collection('legal')
            .doc('legal_data').update({ legal_name: legal_name, rfc: rfc })
    }

    // delivers
    getDelivers() {
        const providerId = this.currentUserId;
        return firebase.firestore()
            .collection('colaborators')
            .where('providerId', '==', providerId)
            .where('available', '==', true)
    }



}