import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import * as pattern from '../../constants/pattern-constants';


@Injectable({
    providedIn: 'root'
})
export class FormContactoService {

    constructor() { }

    formGroup() {
        return new UntypedFormGroup({
            name: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
            email: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.EMAIL_PATTERN)]),
            phoneNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.PHONE_PATTERN)]),
            comments: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        });
    }

    getFormValidationErrors(createForm : UntypedFormGroup) {
        var errors = [];
        Object.keys(createForm.controls).forEach(key => {
        const controlErrors: ValidationErrors = createForm.get(key).errors;
          if (controlErrors != null) {
            
                Object.keys(controlErrors).forEach(keyError => {
                  if(key == 'name') key = 'El campo nombre es requerido';
                  if(key == 'email') key = 'El email es requerido';
                  if(key == 'comments') key = 'Commentarios requeridos';
                  if(key == 'phoneNumber') key = 'El número de teléfono es requerido y debe ser de 10 digitos';
    
                  errors.push({name:key})
                  // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
              }
          }
        );
        return errors;
    }

    clear(createForm : UntypedFormGroup){
        createForm.get('name').setValue("");
        createForm.get('email').setValue("");
        createForm.get('comments').setValue("");
        createForm.get('phoneNumber').setValue("");
    }

   
    

}