import { Component } from "@angular/core";
import { GuardsCheckEnd, GuardsCheckStart, Router } from "@angular/router";
import { ProviderUser } from "./models/Provider";
import { EmmiterService } from "./shared/services/emmiter.service";
import { ProfileService } from "./shared/services/profile.service";

import { AngularFireStorage } from '@angular/fire/storage';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {

  loading : boolean;
  constructor(private router: Router, private dataUserS: ProfileService,private storage: AngularFireStorage){
    this.router.events.subscribe(event => {
      if (event instanceof GuardsCheckStart) {
        this.loading = true;
      }     
      if (event instanceof GuardsCheckEnd) {
        this.loading = false;
      } 
    });

   

  }



}
