import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


import { Observable } from 'rxjs';
import  firebase from "firebase/app"
import 'firebase/firestore';
import { AdI } from 'src/app/models/Ad';

@Injectable({
    providedIn: 'root'
})
export class AdService {

    //Ad equivale a servicios que ofrece un  proveedor.

    constructor(private firestore: AngularFirestore) { }

     // Returns current user UID
     get currentUserId(): string {
        return firebase.auth().currentUser.uid;
    }

    createAd(UUID: string, ad: AdI) {
        delete ad.images;
        return new Promise((resolve, reject) => {
            this.firestore.collection('ads').doc(UUID).set(ad).then((res: any) => {
                resolve(res);
            }).catch((error) => { reject(error) });
        });
    }

    updateAddWithImages(adId: string, images: string[]) {
        return this.firestore.collection('ads').doc(adId).update({
            images: images
        })
    }

    //Obtenemos los primeros 15 anuncios 
    fetchAdsFirstTime() {
        const providerId = this.currentUserId;
        return this.firestore.collection('ads', ref =>
            ref.where('providerId', '==', providerId)
                .where("enable", "==", true)
                .orderBy("createdAt", 'desc')
                .limit(15)
        ).snapshotChanges()
    }

    //Obtenemos los siguientes 8 anuncios Antes estaba en 2
    fetchNextAds(lastreference) {
        const providerId = this.currentUserId;
        return this.firestore.collection('ads', ref =>
            ref.where('providerId', '==', providerId)
                .where("enable", "==", true)
                .orderBy('createdAt', 'desc')
                .limit(8)
                .startAfter(lastreference)
        ).snapshotChanges()
    }

    getAd(adId: string) {
        return firebase.firestore()
            .collection("ads")
            .where("addId", "==", adId)
    }

    // Obtenemos el total reservaciones completadas sobre el anuncio en particular de  CHECAR ESTOO!! OBTENER RESERBACIONES COMPLETADAS
    getCountReservations(adId: string) {
        return this.firestore.collection("reservations", ref =>
            ref.where("adId", "==", adId)
                .where('status', '==', 'Finalizado')  //CHECAR!!
        ).get();
    }

    fetchAd(adId: string) {
        return this.firestore.collection('ads').doc(adId).snapshotChanges();
    }

    remove(adId: string) {
        return this.firestore.collection('ads').doc(adId).update({ enable: false });
    }

    removeImageFromAd(adId: string, imageUrl: string) {
        return this.firestore.collection('ads')
            .doc(adId)
            .update({
                images: firebase.firestore.FieldValue.arrayRemove(imageUrl)
            });
    }

    updateDisponibility(adId: string, available: boolean) {
        return this.firestore.collection('ads')
            .doc(adId).update({ available: available });
    }

    updateAd(adId: string, title: string, description: string, price: string) {
        return this.firestore.collection('ads')
            .doc(adId)
            .update({ title: title, description: description, price: price });
    }

    /*No available :::::::: */
    getMyAds(): Observable<firebase.firestore.QuerySnapshot> {
        const providerId = this.currentUserId;
        return this.firestore.collection<AdI>("ads",
            ref => ref.where("providerId", "==", providerId)).get();
    }
    getAdById(adId: string) {
        return this.firestore.collection("ads").doc(adId).get();
    }
}