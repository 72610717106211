import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import * as pattern from '../../constants/pattern-constants';


@Injectable({
    providedIn: 'root'
})
export class FormPreregistroService {




    constructor() { }


    // FormGroup para preregistro
    formGroup() {
        return new UntypedFormGroup({
            ocupacion: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
            name: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
            email: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.EMAIL_PATTERN)]),
            phoneNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.PHONE_PATTERN)]),
            address:new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
            otra_ocupacion:new UntypedFormControl(''),
        });
    }

    getFormValidationErrors(createForm : UntypedFormGroup) {
        var errors = [];
        Object.keys(createForm.controls).forEach(key => {
        const controlErrors: ValidationErrors = createForm.get(key).errors;
          if (controlErrors != null) {
            
                Object.keys(controlErrors).forEach(keyError => {
                  if(key == 'ocupacion') key = 'La ocupación es requerida';
                  if(key == 'name') key = 'El nombre es requerido';
                  if(key == 'email') key = 'El correo es requerido';
                  if(key == 'phoneNumber') key = 'El número de teléfono es requerido y debe ser de 10 digitos';
                  if(key == 'address') key = 'La dirección es requerida';
    
                  errors.push({name:key})
                  // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
              }
          }
        );
        return errors;
    }

    fetchOcupations(){
       return  [
        {name:"Abogado"}, 
        {name:"Albañil"} ,
        {name:"Carpintero"},
        {name:"Cirujano"}, 
        {name:"Cocinero"},
        {name:"Chef"} ,
        {name:"Personal de limpieza"} ,
        {name:"Contador"},
        {name:"Dermatólogo"}, 
        {name:"Decorador"},
        {name:"Educador"},
        {name:"Electricista"},
        {name:"Enfermero"},
        {name:"Estilista"}, 
        {name:"Fisioterapeuta"},
        {name:"Fontanero"},
        {name:"Fotógrafo"}, 
        {name:"Fumigador"},  
        {name:"Hojalatero"},
        {name:"Herrero"},
        {name:"Jardinero"} ,
        {name:"Maestro"} ,
        {name:"Mecánico"} ,
        {name:"Médico"} ,
        {name:"Modelo"} ,
        {name:"Peluquero"},
        {name:"Pintor"} ,
        {name:"Plomero"} ,
        {name:"Técnico en electrónica"},
        {name:"Técnico en refrigeradores y lavadoras"},
        {name:"Veterinario"},
        {name:"Otro"},
      ]
        
    }


  
    

}