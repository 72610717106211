import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facebook',
  templateUrl: './facebook.component.html',
  styleUrls: ['./facebook.component.css']
})
export class FacebookComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var chatbox = document.getElementById('fb-customer-chat');
    chatbox.setAttribute("page_id", "177894080566365");
    chatbox.setAttribute("attribution", "biz_inbox");
  }

}
