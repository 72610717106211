import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CustumerI, SubscriptionI, DataCustumerI, PaymentI } from 'src/app/models/Payment';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class SearchEngineService {

    URI_BASE_CHARGE: string;

    constructor( private httpClient: HttpClient) { }
    
    syncProviders(){
        //return this.httpClient.get("http://localhost:4000/api/v1/providers/sync");
        return this.httpClient.get('https://seek-api-search-engine-v1-5j1x.vercel.app/api/v1/providers/sync');
        //return this.httpClient.get('https://searchengine.seekmefy.com/api/v1/providers/sync');
    }

    
}