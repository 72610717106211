import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { delay, map, retryWhen } from "rxjs/operators";
import {  Subscription } from 'rxjs';
import { ProfileService } from '../services/profile.service';
import { ProviderUser } from 'src/app/models/Provider';
@Injectable({
  providedIn: 'root'
})
export class VerifySuspendedAccountProviderGuard implements CanActivate {
  
  subscriptionUser$: Subscription;
  user: ProviderUser;

  constructor(private router: Router, private profileS: ProfileService) { }
  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
        this.profileS.getDataProvider()
        .then(res => {
            const user = res.data() as ProviderUser;
            if(user.isActive){
                if(user.suspendedAccount){ 
                    /*Permitir entrar a ruta para actualizar pago*/
                    resolve(true);
                }else{
                    this.router.navigateByUrl('/');
                    resolve(false);
                }
            }else{
                this.router.navigateByUrl('/');
                resolve(false);
            }
        })
    })
  }
}