import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';
import { ProviderUser } from '../../models/Provider';
import { Observable } from 'rxjs';
import firebase from "firebase/app"
import 'firebase/firestore';
import 'firebase/auth';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    
    public userData$: Observable<firebase.User>;

    constructor(private firebaseAuth: AngularFireAuth, private firestore: AngularFirestore) {
        this.userData$ = firebaseAuth.authState;
    }

    // Returns current user UID
    get currentUserId(): string {
        return firebase.auth().currentUser.uid || undefined;
    }

    get user(): any {
        return firebase.auth().currentUser;
    }

    // Returns current user UID
    get email(): string {
        return firebase.auth().currentUser.email ;
    }

    login(email: string, password: string) {
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(email, password).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error);
            });
        });
    }

    createUser(email: string, password: string) {
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(email, password).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error);
            });
        });
    }
    

    storeUser(user: ProviderUser, uuid: string) {
        user.providerId = uuid;
        return new Promise((resolve, reject) => {
            this.firestore.collection('providers').doc(uuid).set(user).then((res: any) => {
                resolve(res);
            }).catch((error) => {
                reject(error)
            });
        });
    }


    getUser(): Promise<any> {
        return new Promise((resolve,reject) => {
            this.firebaseAuth.authState.subscribe(user => {
                resolve(user);
            },error => reject(error))
        })
    }

    recoveryPassword(email: string) {
        return new Promise((resolve, reject) => {
            firebase.auth().sendPasswordResetEmail(email).then(res => {
                resolve("");
            }).catch(error => reject(error));
        })
    }

    changeEmail(newEmail: string) {
        const user = firebase.auth().currentUser;
        return new Promise((resolve, reject) => {
            user.updateEmail(newEmail).then(res => {
                resolve("Updated")
            }).catch(function(error) {
                reject(error)
            });
        })
    }

    

    //NO SE USARA POR AHORA
    createLegalData(providerId: string, email: string) {
        return new Promise((resolve,reject)  =>{
            firebase.firestore().collection('providers')
            .doc(providerId)
            .collection('legal')
            .doc('legal_data').set({
                rfc: 'XAXX010101000',
                legal_name: 'PÚBLICO EN GENERAL',
                email: email
            }).then( (res) => resolve(res))
            .catch(error => reject(error))
        });
    }

}