import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataCustumerI } from 'src/app/models/Payment';
import { ProviderUser } from 'src/app/models/Provider';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { ProfileService } from 'src/app/shared/services/profile.service';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent implements OnInit {

  items = [];

  dataCustumer: DataCustumerI;
  processing:boolean=false;
  subscription: any; //OBTENEMOS LA SUSCRIPCION ACTUAL-JUNTO CON DATOS DE LA TARJETA
  card_number: string;
  card:any;
  suspendedAccount:boolean = true;
  user:ProviderUser;
  anyPlan:boolean =  false;
  constructor(private router: Router,private paymentService: PaymentService, private profileS: ProfileService, private paymentS:PaymentService) { }

  ngOnInit(): void {
    
    this.getSubscriptionStatus();
  }

  
  getSubscriptionStatus() {
    this.processing = true;
    this.profileS.getDataProvider().then(res=>{
      this.user = res.data() as ProviderUser;
     
      this.processing = false;
      this.suspendedAccount = this.user.suspendedAccount;
          if(!this.suspendedAccount){
              this.paymentService.getCustomer().subscribe(res => {
                console.log("Getting customer...");
                
                //With stripe customer not exist then

                if (!this.isNullOrUndefined(res.data())) {
                    this.dataCustumer = res.data() as DataCustumerI; //Before customer_id 
                    this.processing = false;
                    if(this.dataCustumer.isActive){
                      // //This service not work
                      // this.paymentService.verifySubscriptionStatus(this.dataCustumer.custumerId, this.dataCustumer.subscriptionId)
                      //   .subscribe(res => {
                      //       this.card = res.card;
                      //       this.processing = false;
                      //       this.subscription = res;
                      //       const lastnumbers: string = this.subscription.card.card_number;
                      //       this.card_number = lastnumbers.substring(lastnumbers.length - 4);
                      //     });
                    }
                }else{
                    this.anyPlan = true;
                }
              }, error => { console.log(error); this.processing = false; });
          }else{
            this.processing = false;
          }

    })
    
  }

  isNullOrUndefined(value: any) {
    return value === null || value === undefined;
  }


  onOpenModal(){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.add('active');
    popup.classList.add('active');
  }
  
  cerrarModal(accept:boolean, dataCustomer:DataCustumerI){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.remove('active');
    popup.classList.remove('active');
    if(accept) this.cancel(dataCustomer.custumerId, dataCustomer.subscriptionId);
  }


  cancel(customer_subscription, subscription_id) {
    this.processing=true;
    this.paymentService.cancelSubscription(customer_subscription, subscription_id).subscribe(res => {
      
        this.profileS.disableProvider();
        this.paymentS.updateCustomerCancelled();
        this.processing=false;
        this.router.navigateByUrl('/admin/inicio');

    }, (error) => {
      console.log(error);
      this.processing=false;
    })
  }





}
