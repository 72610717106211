import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ProviderUser } from "src/app/models/Provider";
import { AuthService } from "src/app/shared/services/auth.service";
import { PaymentService } from "src/app/shared/services/payment.service";
import { ProfileService } from "src/app/shared/services/profile.service";
import * as pattern from "../../../shared/constants/pattern-constants";
import { uid } from 'uid';


@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
})
export class RegisterComponent implements OnInit {
  
  createForm: UntypedFormGroup;
  provider: ProviderUser;
  loading:boolean=false;

  constructor(private firebaseAuth: AuthService,private profileS:ProfileService ,private router: Router, private toastS: ToastrService, private paymentS:PaymentService) {
    this.createForm = this.formGroup();
     //Initializate Object Provider
     this.provider = {
        name: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        organization: {
            name: 'Nombre del negocio',
            description: 'Añade una descripción',
            adress: 'Añade una ubicación',
            type: 'Añade una Categoria  (construcción, despacho, limpieza, etc...)',
            logoUrl: 'https://firebasestorage.googleapis.com/v0/b/seekmyapp-test.appspot.com/o/logos%2FLogo%20Provider.png?alt=media&token=13d7c927-6969-4b9c-8139-76da10c6cf4a',
            location: {
                latitud: '',
                longitude: ''
            },
            schedule: [],
            coverUrl: 'https://firebasestorage.googleapis.com/v0/b/seekmyapp-test.appspot.com/o/logos%2Fdefault-app-portada.png?alt=media&token=e5e14a2c-d476-4601-a1f8-b22b91277f1a',
            range: 0,
            specialty: '',
            allowCardPayments: false
        },
        createdAt: 0,
        modifiedAt: 0,
        isActive: false,
        photoUrl: 'https://firebasestorage.googleapis.com/v0/b/seekmyapp-test.appspot.com/o/logos%2FCOVER%20PROVIDER.png?alt=media&token=39c12f0c-bc0b-4193-9ae8-d135c34b5e5a',
        fcmToken: '',
        tags: [],
        clabe: '',
        suspendedAccount:false,
        providerId:'',
        raiting : this.getRaintingModel()
      
      }


   }

  ngOnInit(): void {
    this.scroolToStart();
  }

  public  onCreateUser() {
    
  

    if (this.createForm.valid) {
        this.assigningValues()
        const pass = this.createForm.get("pass").value;
        this.loading=true;
        this.firebaseAuth.createUser(this.provider.email, pass).then((res: any) => { //Crea usuario
            const providerId = res.user.uid;
            this.firebaseAuth.storeUser(this.provider, providerId).then((res: any) => {
              
              this.profileS.createDocProvider(providerId) //Creamos obj para guardar datos de identificacion
              .then(()=>{
                  const fullName = this.provider.name + " " + this.provider.lastname;

                  this.paymentS.storeCardAuthorization(
                    {
                      isActive: false,
                      custumerId: '',
                      card_id: '',
                      subscriptionId: '',
                      providerId: providerId
                    }
                  )
                  
                  this.paymentS.storeCustomerOpenPay(providerId,fullName,this.provider.email)
                    .subscribe(res => {
                      console.log(res);
                      this.loading=false;
                       this.router.navigateByUrl('/admin/inicio');
                    },error => {
                      console.log(error);
                      this.router.navigateByUrl('/admin/inicio');
                    });
              });
            
            }).catch((error) => {
                this.loading = false;
                console.log(error);
            });

        }).catch((error) => {
            this.loading=false;
            if (error.code === 'auth/email-already-in-use') {
              this.toastS.error("Este correo ya esta en uso por otra cuenta");
            }
        })

    } else {  this.toastS.show("Verificatu información"); }

}



getRaintingModel() {
  return  [
        {
            weight: 5,
            count: 0
        },
        {
            weight: 4,
            count: 0
        },
        {
            weight: 3,
            count: 0
        },
        {
            weight: 2,
            count: 0
        },
        {
            weight: 1,
            count: 0
        }
    ];
}

assigningValues() {
    let createdAt = Math.round(new Date().getTime() / 1000);
    this.provider.createdAt = createdAt;
    this.provider.modifiedAt = createdAt;
    this.provider.name = this.createForm.get("name").value;
    this.provider.lastname = this.createForm.get("lastname").value;
    this.provider.email = this.createForm.get("email").value;
    this.provider.phoneNumber = this.createForm.get("phoneNumber").value;
    const provider_name = this.createForm.get("name").value + ' ' + this.createForm.get("lastname").value;
    this.provider.tags.push(provider_name)
}

  scroolToStart = () => window.scroll(0, 0);

  formGroup() {
    return new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        lastname: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        email: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.EMAIL_PATTERN)]),
        phoneNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.PHONE_PATTERN)]),
        pass: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.PASS_PATTERN)])
    });
  }

  get name() { return this.createForm.get('name') }
  get lastname() { return this.createForm.get('lastname') }
  get email() { return this.createForm.get('email') }
  get phoneNumber() { return this.createForm.get('phoneNumber') }
  get pass() { return this.createForm.get('pass') }



  //Crear nuevo customer en Mysql 






}
