import { Component, OnInit } from '@angular/core';
import { BannerFI, BannerI } from 'src/app/models/Banner';
import { BannerService } from 'src/app/shared/services/banner.service';
import { UntypedFormGroup } from '@angular/forms';
import { FormCreateBannerService } from 'src/app/shared/services/forms/form-create-banner.service';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from 'src/app/shared/services/profile.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  banners: BannerFI[];
  loading:boolean = false;
  bannerUrl:string;


  createForm: UntypedFormGroup;



  constructor(private usrS: ProfileService,private bannerS: BannerService, private formCreateBanerS: FormCreateBannerService, private toastS:ToastrService) { }

  ngOnInit(): void {
      this.createForm = this.formCreateBanerS.formGroup(); 
      this.fetchOnlineBanners();
  }

  fetchOnlineBanners(){
    this.loading=true;
    this.bannerS.getBanners().subscribe(res => {
      this.banners = [];
      res.forEach(banner => {
        const item = banner.data() as BannerFI;
        this.banners.push(item);
       
      })
      this.loading=false;
    },error=>this.loading=false)
  }


  onOpenModal(banner:BannerI){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.add('active');
    popup.classList.add('active');
    this.bannerUrl = banner.image;
  }
  
  cerrarModal(modal_id, popup_id){
    var overlay = document.getElementById(modal_id),
    popup = document.getElementById(popup_id);
    overlay.classList.remove('active');
    popup.classList.remove('active');
    this.bannerUrl = null;
    
  }



  formatMoney(value){
    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value); /* $2,500.00 */
  }




  openCreateBannerModal(){
    var overlay = document.getElementById('create-banner-form'),
    popup = document.getElementById('popup_2');
    overlay.classList.add('active');
    popup.classList.add('active');
  }


  onSaveBanner(){
    
    if(this.createForm.valid){
      const { title,description,link_banner } = this.createForm.value;
      
      var banner : BannerFI;

      let date = new Date(); 
      let created_date: number = Math.round(date.getTime() / 1000);

      banner = {
        provider_id: this.usrS.currentUserId,
        title: title,
        description: description,
        link_thumb: link_banner,
        creation_date: created_date,
        is_active: true,
        zone_ste_id: 1,
        banner_id:""
      }

      this.loading = true;
      this.bannerS.save(banner)
        .then(res=>{
          this.loading = false;
          this.cerrarModal('create-banner-form','popup_2'); 
          this.toastS.success("Banner creado correctamente");
          this.fetchOnlineBanners();
          this.createForm.reset();
        }).catch(error=>{
          this.toastS.error("Error al enviar la información, verifica tu información");
          this.loading = false;
          console.log(error);
        }) 
    }else{
      this.toastS.show("Todo los campos son obligatorios")
    }
  }

  onRemoveBanner(banner: BannerFI){
    this.loading = true;
    this.bannerS.removeBanner(banner.banner_id)
        .then(res=>{
          this.loading = false;
          this.cerrarModal('create-banner-form','popup_2'); 
          this.toastS.success("Banner eliminado correctamente");
          this.fetchOnlineBanners();
        }).catch(error=>{
          this.toastS.error("Error al eliminar, intente mas tarde");
          this.loading = false;
          console.log(error);
        }) 
  }



}
