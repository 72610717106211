import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import firebase from "firebase/app"
import 'firebase/firestore';
import 'firebase/auth';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NO_EMPTY_AND_SPACES_PATTERN, NO_EMPTY_PATTERN } from 'src/app/shared/constants/pattern-constants';
import { environment } from '../../../../environments/environment';
import { Card, CustumerI, DataCustumerI, SubscriptionI } from 'src/app/models/Payment';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { OpenPayErrors } from 'src/app/shared/services/errors/open-pay-errors.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { Router } from '@angular/router';
import { FormOpenPayService } from 'src/app/shared/services/forms/form-openpay.service';
import { ProviderUser } from 'src/app/models/Provider';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

declare var OpenPay: any;

@Component({
  selector: 'app-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.css']
})
export class SubscriptionFormComponent implements OnInit {

  @Input('isUpdatePay') isUpdatePay: boolean;
  
  createForm: UntypedFormGroup;
  userEmail:string;
  deviceSessionId: any;
  card: Card;
  processing:boolean = false;
  providerId:string;
  error:boolean=false;
  dataCustumer: DataCustumerI;
  customer_id:string;
  providerUser : ProviderUser;

  suscriptionLink: String;

  constructor(private sanitizer: DomSanitizer,private formOpenPay:FormOpenPayService,private router: Router,private profileS: ProfileService,private openpayErrors:OpenPayErrors, private toastr: ToastrService, private authS: AuthService, private paymentS: PaymentService) {
    //this.initializeOpenPayConfigurations();
    // this.createForm = this.formOpenPay.formGroup();
    // this.userEmail = this.authS.email;
    // this.providerId = this.authS.currentUserId;
    // this.paymentS.getProvider().subscribe((data:any) => {
    //     this.customer_id = data.customer_id == undefined ? null : data.customer_id;
    //     console.log(this.customer_id);
    // },error => { console.log(error); });

    
  }


  ngOnInit(): void {
    this.getProvider();
  }
 
  public  getProvider(){
    this.profileS.getProfile().subscribe(res => {
      this.providerUser = res.payload.data() as ProviderUser;
      this.suscriptionLink = "https://buy.stripe.com/test_4gw5nZd9n6ZG6xa8ww?prefilled_email=" + this.providerUser.email;
    })
  }








  isNullOrUndefined(value: any) {
    return value === null || value === undefined;
  }

  initializeOpenPayConfigurations() {
    const ID: string = environment.OPEN_PAY_ID;
    const API_KEY: string = environment.OPEN_PAY_API_KEY;
    const SAND_BOX_MODE: boolean = environment.OPEN_PAY_SANDBOX_MODE;
    OpenPay.setId(ID);
    OpenPay.setApiKey(API_KEY);
    OpenPay.setSandboxMode(SAND_BOX_MODE);
    this.deviceSessionId = OpenPay.deviceData.setup();
  }

  asssignValues() {
    this.card = {
        holder_name: this.holder_name.value,
        number: this.number.value,
        expirationMonth: this.month.value,
        expirationYear: this.year.value,
        cvv: this.cvv.value,
    }
  }
  
  onPrepareCard() {

    if (this.createForm.valid) {
        var accept:any = document.getElementById('checkedTerms');
        if(accept.checked){
          this.asssignValues();
          if (this.card !== null) {
              if (OpenPay.card.validateCardNumber(this.card.number)) {
                  this.createCardToken();
              } else { 
                this.toastr.error('Número de tarjeta invalido.', 'Error',{timeOut: 3000,});
              }    
          }
        }else{
          this.toastr.warning('Debes aceptar la politica de privacidad y terminos de Seekmefy.', 'Error',{timeOut: 3000,});
        }
    } else {
        this.toastr.error('Verifique los datos de la tarjeta.', 'Error',{timeOut: 3000,});
    }
  }

  createCardToken() {
    this.processing = true;

    const card_holder_name = this.card.holder_name;
    const country = this.createForm.get('country').value;
    const state = this.createForm.get('state').value;
    const postal_code = this.createForm.get('postal_code').value;
    const email = this.userEmail;
    const country_code = "MX";

    OpenPay.token.create({
        "card_number": this.card.number,
        "holder_name": card_holder_name,
        "expiration_year": this.card.expirationYear,
        "expiration_month": this.card.expirationMonth,
        "cvv2": this.card.cvv,
        "address": {
            "city": country,
            "line3": "Calle S/N",
            "postal_code": postal_code,
            "line1": "/",
            "line2": "/",
            "state": state,
            "country_code": country_code
        }
    }, result => {

        const card_token: string = result.data.id;
        const deviceSessionId = this.deviceSessionId;
        
        var customer_id = this.customer_id ? this.customer_id :'';
        var isRegistered = this.customer_id ? true : false;

        

        const customer: CustumerI = {
            device_session_id: deviceSessionId,
            email: email,
            last_name: "",
            name: card_holder_name,
            source_id: card_token,
            address: {
                city: country,
                country_code: country_code,
                line1: 'Calle S/N',
                line2: '/',
                line3: '/',
                postal_code: postal_code,
                state: state,
            },
            isRegistered: isRegistered, 
            customer_id: customer_id  
        }

        this.paymentS.createProvider(customer).subscribe((res: any) => {
            // Creando customer de OpenPay
            console.log(res);
            let subscription: SubscriptionI = {
              customer_id: res.id_customer,
              card_id: res.card_id
            }

            let customer: DataCustumerI = {
                isActive: false,
                card_id: subscription.card_id,
                custumerId: subscription.customer_id,
                providerId: this.providerId,
                subscriptionId: ''
            } //Objeto guardado a Firabase

            this.paymentS.storeCardAuthorization(customer);
            this.processing = false;

            // Creando suscripcion Si todo sale bien Redirijo a Inicio

            this.createSubscription(subscription);

        }, error => { 
            this.processing = false; 
            this.error = false;
            this.toastr.error('Ocurrio un error desconocido,intente con otra tarjeta. ', 'Error',{timeOut: 3000,});
           
        });
    }, error => {
        this.processing = false; this.error = true;
        const messageError = this.openpayErrors.messageCardError(error);
        this.toastr.error(messageError, 'Error',{timeOut: 3000,});
    })

  }

  createSubscription(subscription:SubscriptionI){
    this.paymentS.createSubscription(subscription).subscribe(res => {
      this.processing = false;
      this.paymentS.updateCustumerSubscription(res.id_subscription);
      this.paymentS.updateCustumerStatus();
      this.profileS.enableProvider();
      this.profileS.enableProviderRange();

      if(!this.isUpdatePay){
        console.log(subscription);
        //Registro  CustomerId OpenPay en MYSQL
        this.paymentS.updateCustomerOpenPay(subscription.customer_id)
        .subscribe(res=>{
          //Lanzar Pago exitoso
          this.onOpenModal(); //this.router.navigateByUrl('/admin/inicio', { replaceUrl: true });
        },error=>{ 
          console.log(error)
        })
      }else{
        this.onOpenModal();
      }
      
    }, error => {
      this.processing = false;
      this.toastr.error('Error desconocido, contacte a soporte', 'Error',{timeOut: 3000,});
    });
  }

  onOpenModal(){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.add('active');
    popup.classList.add('active');
  }
  
  cerrarModal(accept:boolean){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.remove('active');
    popup.classList.remove('active');
    this.router.navigateByUrl('/admin/inicio', { replaceUrl: true });
  }

  get number() { return this.createForm.get('number') }
  get cvv() { return this.createForm.get('cvv') }
  get holder_name() { return this.createForm.get('holder_name') }
  get month() { return this.createForm.get('month') }
  get year() { return this.createForm.get('year') }
  get country() { return this.createForm.get('country') }
  get state() { return this.createForm.get('state') }
  get postal_code() { return this.createForm.get('postal_code') }

  
}
