export const PASS_OR_EMAIL_IVALID: string = 'Email o contraseña incorrectos';
export const COMPLET_FIELDS: string = 'Complete los campos.';
export const STARING_SESION: string = 'Iniciando sesión...';
export const WAIT_REGISTERING: string = 'Procesando...';
export const VERIFY_YOUR_DATA: string = 'Verifique su información';
export const ERROR_TO_PROCESS_INFO: string = 'Error al procesar la información';
export const PROCESSING: string = 'Procesando...';
export const SUCCES_PASSWORD_REQUEST: string = 'Te hemos enviado un correo para que puedas cambiar tu contraseña.';
export const ERROR_PROCESSING: string = 'Error al procesar la información, compruba tu conexión a internet.';
export const EMAIL_IVALID: string = 'EL correo no pertenece a ninguna de nuestras cuentas, o esta inhabilitada';
export const EMAIL_NO_MATCH: string = 'El correo no es valido';
export const EMAIL_COLABORATOR: string = 'Su correo pertenece a una cuenta de colaborador, recurra al administrador para crear una nueva.';
export const ACCOUNT_DISSABLED: string = 'Tu cuenta a sido deshabilitada por el administrador';
export const MANY_REQUEST: string = 'Demaciados intentos, intenete mas tarde';