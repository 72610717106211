import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})

export class OpenPayErrors {

    constructor() { }

    messageCardError(error: any) {
        switch (error.data.error_code) {
            case 2005: return "La fecha de expiración de la tarjeta es anterior a la fecha actual.";
            case 1001: return "Verifica el codigo CVV2.";
            case 2006: return "El código de seguridad de la tarjeta (CVV2) no fue proporcionado..";
            case 2009: return "El codigo CVV2 de seguridad es invalido.";
            case 2011: return "Tipo de tarjeta no soportada..";
            case 3001: return "La tarjeta fue rechazada por el banco.";
            case 3002: return "La tarjeta ha expirado.";
            case 3003: return "La tarjeta no tiene fondos suficientes.";
            case 3004: return "La tarjeta ha sido reportada como robada.";
            case 3005: return "Riesgo de fraude detectado por el sistema antifraude.";
            case 3009: return "La tarjeta fue reportada como robada.";
            case 3010: return "El banco ha restringido la tarjeta.";
            case 3011: return "El banco ha solicitado que se retenga la tarjeta.";
            case 3012: return "Se requiere autorización bancaria para este cargo.";
            case 1001: return "La longitud de codigo cvv2 debe ser de 3 dígitos.";
            case 1000: return "Ocurrió un error con nuestro procesador de pagos";
            default: return "Error desconocido, intente con otra tarjeta."
        }
    }

}