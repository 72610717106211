import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";
import { ProfileComponent } from "./views/profile/profile.component";

// components for views and layouts

import { AdminNavbarComponent } from "./components/navbars/admin-navbar/admin-navbar.component";
import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";
import { CardPageVisitsComponent } from "./components/cards/card-page-visits/card-page-visits.component";
import { CardProfileComponent } from "./components/cards/card-profile/card-profile.component";
import { CardSettingsComponent } from "./components/cards/card-settings/card-settings.component";
import { CardSocialTrafficComponent } from "./components/cards/card-social-traffic/card-social-traffic.component";
import { CardStatsComponent } from "./components/cards/card-stats/card-stats.component";
import { CardTableComponent } from "./components/cards/card-table/card-table.component";
import { FooterAdminComponent } from "./components/footers/footer-admin/footer-admin.component";
import { FooterComponent } from "./components/footers/footer/footer.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { HeaderStatsComponent } from "./components/headers/header-stats/header-stats.component";
import { IndexNavbarComponent } from "./components/navbars/index-navbar/index-navbar.component";
import { MapExampleComponent } from "./components/maps/map-example/map-example.component";
import { IndexDropdownComponent } from "./components/dropdowns/index-dropdown/index-dropdown.component";
import { TableDropdownComponent } from "./components/dropdowns/table-dropdown/table-dropdown.component";
import { PagesDropdownComponent } from "./components/dropdowns/pages-dropdown/pages-dropdown.component";
import { NotificationDropdownComponent } from "./components/dropdowns/notification-dropdown/notification-dropdown.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { UserDropdownComponent } from "./components/dropdowns/user-dropdown/user-dropdown.component";

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from "../app/shared/services/auth.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { SubscriptionFormComponent } from './components/bussines/subscription-form/subscription-form.component';
import { SubscriptionComponent } from "./views/admin/subscription/subscription.component";
import { PanelComponent } from './views/admin/panel/panel.component';
import { TermsComponent } from './views/legal/terms/terms.component';
import { PrivacyComponent } from "./views/legal/privacy/privacy.component";
import { UpdateSubscriptionComponent } from './views/admin/update-subscription/update-subscription.component';

import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SuccessPaymentComponent } from './views/admin/welcome/success-payment/success-payment.component';
import { CardRegisteredsComponent } from './views/admin/cards/card-registereds/card-registereds.component';



import { NgxLoadingModule } from 'ngx-loading';
import { PersonalComponent } from './views/admin/docs/personal/personal.component';
import { PlanComponent } from './views/admin/plans/plan/plan.component';
import { ReservationComponent } from './views/admin/main/reservation/reservation.component';
import { EarningComponent } from './views/admin/main/earning/earning.component';
import { PaymentComponent } from './views/admin/main/payment/payment.component';
import { BannerComponent } from './views/admin/main/banner/banner.component';
import es from '@angular/common/locales/es';

import { registerLocaleData } from '@angular/common';

registerLocaleData(es);
import {AngularFireStorageModule} from "@angular/fire/storage";
import { PreregisterComponent } from './views/preregister/preregister.component';
import { MantenimientoComponent } from './views/mantenimiento/mantenimiento.component';
import { SoporteComponent } from './views/soporte/soporte.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CardServicesComponent } from './components/cards/card-services/card-services.component';
import { AppUsuarioComponent } from './components/descarga/app-usuario/app-usuario.component';
import { AppPrestadorComponent } from './components/descarga/app-prestador/app-prestador.component';
import { StepsComponent } from './components/steps/steps.component';
import { QuestionsComponent } from './components/questions/questions.component';
import { WhatsappComponent } from './components/rdsSociales/whatsapp/whatsapp.component';
import { FacebookComponent } from './components/rdsSociales/facebook/facebook.component';
import { NavbarComponent } from './components/navbar/navbar/navbar.component';
import { MainComponent } from './views/seek/admin/main/main.component';
import { ProviderComponent } from './views/seek/admin/provider/provider.component';
import { StripeFormSubscriptionComponent } from './components/bussines/stripe-form-subscription/stripe-form-subscription.component';
import { LandingPlansComponent } from './components/plans/landing-plans/landing-plans.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    IndexDropdownComponent,
    PagesDropdownComponent,
    TableDropdownComponent,
    NotificationDropdownComponent,
    UserDropdownComponent,
    SidebarComponent,
    FooterComponent,
    FooterSmallComponent,
    FooterAdminComponent,
    CardPageVisitsComponent,
    CardProfileComponent,
    CardSettingsComponent,
    CardSocialTrafficComponent,
    CardStatsComponent,
    CardTableComponent,
    HeaderStatsComponent,
    MapExampleComponent,
    AuthNavbarComponent,
    AdminNavbarComponent,
    IndexNavbarComponent,
    AdminComponent,
    AuthComponent,
    MapsComponent,
    SettingsComponent,
    TablesComponent,
    LoginComponent,
    RegisterComponent,
    IndexComponent,
    LandingComponent,
    ProfileComponent,
    SubscriptionFormComponent,
    SubscriptionComponent,
    PanelComponent,
    TermsComponent,
    PrivacyComponent,
    UpdateSubscriptionComponent,
    SuccessPaymentComponent,
    CardRegisteredsComponent,
    PersonalComponent,
    PlanComponent,
    ReservationComponent,
    EarningComponent,
    PaymentComponent,
    BannerComponent,
    PreregisterComponent,
    MantenimientoComponent,
    SoporteComponent,
    CarouselComponent,
    CardServicesComponent,
    AppUsuarioComponent,
    AppPrestadorComponent,
    StepsComponent,
    QuestionsComponent,
    WhatsappComponent,
    FacebookComponent,
    NavbarComponent,
    MainComponent,
    ProviderComponent,
    StripeFormSubscriptionComponent,
    LandingPlansComponent
  ],
  imports: [
    BrowserModule, 
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot({positionClass: 'toast-bottom-center',}),
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({}),
    AngularFireStorageModule

  ],
  providers: [AngularFirestore, AuthService,{ provide: LOCALE_ID, useValue: 'es-*' } ],
  bootstrap: [AppComponent],
})
export class AppModule {}
