import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {environment} from "../../../environments/environment"
import * as firebase from "firebase";
import { uid } from 'uid';

export interface Contact{
    name?:string;
    phone?:string;
    email?:string;
    commentaries?:string;
}

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor(private httpClient:HttpClient,private firestore: AngularFirestore) { }


    //Mandar datos para preregistro
    sendInfo(fullname,occupation,email,phone,address) {
        const URL_BASE = environment.URI_BASE;
        return this.httpClient.post(URL_BASE +'/preregistro',{fullname,occupation,email,phone,address});
    }

    sendFormContact(contact:Contact){
        return new Promise((resolve, reject) =>{
            var formatData = this.dataFormData(contact);
            const URL_BASE = environment.URI_BASE;
            var url = 'https://seekmefy.com/api/contactos';
            
            fetch(url, {
                method: 'POST',
                body: formatData,
                mode:'no-cors'
            }).then( (success) => { resolve(true); 
            })["catch"]( (error) => {  reject(false); });
        });
    }

    dataFormData(contact:Contact): FormData{
        const formatData = new FormData();
        formatData.append('name', contact.name);
        formatData.append('commentaries', contact.commentaries);
        formatData.append('email', contact.email);
        formatData.append('phone', contact.phone);
        return formatData;
    }


    createUserPreregister(ocupacion,name,email,phoneNumber,address) {
        const uuid:string = uid(32);
        return new Promise((resolve, reject) => {
            this.firestore.collection("preregistro-users").doc(uuid).set({
                ocupacion:ocupacion,
                name:name,
                email:email,
                phoneNumber:phoneNumber,
                address:address
            })
            .then(( res) =>  {
                resolve(res);
            })
            .catch(function(error) {
                reject(error)
            });
        });
    }
    
}