import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProviderUser } from 'src/app/models/Provider';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { PlatformLocation } from '@angular/common'

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit {

  name_provider:string;
  user:ProviderUser;
  public loading = false;
  doc:any;
  constructor(location: PlatformLocation,private profileS: ProfileService, private toastS:ToastrService) {
    
   }

  ngOnInit(): void {
    this.loading = true;
    this.profileS.getDataProvider().then(res => {
      this.loading = false;
      const user = res.data() as ProviderUser;
      this.user = user;
      this.name_provider = user.name + ' ' +user.lastname;
    }).catch(error =>{
      this.loading = false;
    } );

    this.fetchDocs();
  }

  fetchDocs(){
    this.profileS.getDocsProvider().get()
                 .then(data => {
                    this.doc = data.data();
                    var values = [];
                    
                    if(! this.doc.frontalCredential.uploaded){
                      values.push(1);
                    }

                    if(! this.doc.backCredential.uploaded){
                      values.push(2);
                    }

                 })
                 .catch(error=> console.log(error))
  }


}
