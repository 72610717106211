import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { loadStripe, Stripe, StripeCardElementOptions, StripeElementsOptions, StripeCardElement} from '@stripe/stripe-js';

const STRIPE_PUBLIC_KEY = 'pk_test_51KeRZUJRT5WECtWJT8WQK2sCk78ojAgHudNDsHts0kelNMhFeHm8U35hFpOvHrtKwJicD9fnF72tyXKRDBp8v3ai00Ln6GNXGO';


@Component({
  selector: 'app-stripe-form-subscription',
  templateUrl: './stripe-form-subscription.component.html',
  styleUrls: ['./stripe-form-subscription.component.css']
})
export class StripeFormSubscriptionComponent implements OnInit {
  stripe!: Stripe;
  @ViewChild('cardElement') cardElement!: ElementRef;
  card!: StripeCardElement;

  constructor() {}

  ngOnInit(): void {
    loadStripe(STRIPE_PUBLIC_KEY).then(stripe => {
      this.stripe = stripe;
      const elements = this.stripe.elements();

      const style: StripeCardElementOptions['style'] = {
        base: {
          fontFamily: 'Arial, sans-serif',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
        },
      };



      this.card = elements.create('card', { style });
      this.card.mount(this.cardElement.nativeElement);
    });
  }

  create(event: Event): void {
    event.preventDefault();

    this.stripe.createToken(this.card).then(result => {
      const errorElement = document.getElementById('card-errors');
      if (result.error) {
        errorElement!.textContent = result.error.message;
      } else {
        console.log(result.token);
        // Enviar el token a tu servidor para procesar el pago
      }
    });
  }
}
