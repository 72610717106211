import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EarnPaymentService } from 'src/app/shared/services/earn-payments.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  transactionts = new Array();
  loading:boolean=false;
  subscription$: Subscription;
  constructor(private earnPaymentS: EarnPaymentService) { }

  ngOnInit(): void {
    this.fetchTransactions();
  }


  fetchTransactions(){
    this.loading=true;
      this.subscription$ = this.earnPaymentS.fetchTransatiosProcesed()
      .subscribe((res:any)=>{
        res.map(transaction => {
          const dateSeconds =  new Date(transaction.created_at).getTime() / 1000;
          transaction.created_at = dateSeconds;
          transaction.total = this.formatMoney(transaction.total);
        })
        this.transactionts = res;
        this.loading=false;
        console.log(this.transactionts);
      },error => {
          this.loading=false;
      })
  }

  ngOnDestroy(){
    this.subscription$.unsubscribe();
  }

  onOpenModal(transaction){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.add('active');
    popup.classList.add('active');
  }
  
  cerrarModal(){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.remove('active');
    popup.classList.remove('active');
  }

  formatMoney(value){
    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value); /* $2,500.00 */
  }

}
