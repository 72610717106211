import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Contact, ContactService } from 'src/app/shared/services/contact.service';
import { FormContactoService } from 'src/app/shared/services/forms/form-contacto.service';

@Component({
  selector: 'app-soporte',
  templateUrl: './soporte.component.html'
})
export class SoporteComponent implements OnInit {

  createForm: UntypedFormGroup;
  
  errors = [];

  loading:boolean = false;


  constructor(private formContactS: FormContactoService, private toastS:ToastrService, private contactS: ContactService) { }

  ngOnInit(): void {
    this.createForm = this.formContactS.formGroup(); 
  }


  onSendFormContact(){
    if(this.createForm.valid){
      const {name,email,phoneNumber,comments} = this.createForm.value;
      var contact : Contact;

      contact = {
        email:email,
        commentaries:'TEMA SOPORTE '+comments,
        phone:phoneNumber,
        name:name
      }

      this.loading=true;
      this.contactS.sendFormContact(contact)
        .then(res=>{
          this.loading = false;
          this.onOpenModal();
        }).catch(error=>{
          this.toastS.error("Error al enviar la información, intentalo más tarde");
          this.loading = false;
          console.log(error);
        }) 
    }else{
      this.toastS.show("Verifica tu información")
    }
  }


  onOpenModal(){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.add('active');
    popup.classList.add('active');
  }
  
  cerrarModal(accept:boolean){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.remove('active');
    popup.classList.remove('active');
    if(accept) {
      this.formContactS.clear(this.createForm);
    }
  }

}
