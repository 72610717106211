import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
})
export class SubscriptionComponent implements OnInit {
  
  constructor() {}

  ngOnInit(): void {}
}
