import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/services/auth.service";
import firebase from "firebase/app"
import 'firebase/firestore';
import 'firebase/auth';

@Component({
  selector: "app-auth-navbar",
  templateUrl: "./auth-navbar.component.html",
})
export class AuthNavbarComponent implements OnInit {
  navbarOpen = false;
  auth = false;
  
  constructor(private authS:AuthService) {}

  ngOnInit(): void {
    console.log(this.authS.user)
    if(firebase.auth().currentUser){
      this.auth = true;
    }

    /* Menu */
    let menuOpen = false;
    const menuButton = document.getElementById("menu-button");
    const menuIcon = document.getElementById("menu-icon");
    const navLinks = document.getElementById("nav-links");
    
    function handleMenuButtonClick() {
      if (menuOpen === false) {
        navLinks.classList.replace("hidden", "block");
        menuIcon.setAttribute(
          "d",
          "M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
        );
        menuOpen = true;
      } else {
        navLinks.classList.replace("block", "hidden");
        menuIcon.setAttribute("d", "M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z");
        menuOpen = false;
      }
    }
    menuButton.addEventListener("click", handleMenuButtonClick);
  }

  /* end menu */

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }

  /* Secciones */
  acercaDe() {
    document.getElementById("acercaDe").scrollIntoView({behavior: "smooth"});
  }

  downloadAppOne() {
    document.getElementById("appProveedor").scrollIntoView({behavior: "smooth"});
  }
  
  serviciosPopulares(){
    document.getElementById("serPopulares").scrollIntoView({behavior: "smooth"});
  }

  pasoPaso() {
    document.getElementById("pasoPaso").scrollIntoView({behavior: "smooth"});
  }

  downloadAppTwo() {
    document.getElementById("appUsuario").scrollIntoView({behavior: "smooth"});
  }

  contact() {
    document.getElementById("contacto").scrollIntoView({behavior: "smooth"});
  }

  question() {
    document.getElementById("preguntas").scrollIntoView({behavior: "smooth"});
  }

  
}
