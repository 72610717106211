import { Component, OnInit } from '@angular/core';
import { DataCustumerI } from 'src/app/models/Payment';
import { PaymentService } from 'src/app/shared/services/payment.service';

@Component({
  selector: 'app-card-registereds',
  templateUrl: './card-registereds.component.html',
  styleUrls: ['./card-registereds.component.css']
})
export class CardRegisteredsComponent implements OnInit {

  dataCustumer: DataCustumerI;
  processing:boolean=false;
  subscription: any; //OBTENEMOS LA SUSCRIPCION ACTUAL-JUNTO CON DATOS DE LA TARJETA
  card_number: string;
  card:any;
  
  constructor(private paymentService: PaymentService) { }

  ngOnInit(): void {
    this.getSubscriptionStatus();  
  }

  getSubscriptionStatus() {
    this.processing = true;
    this.paymentService.getCustomer().subscribe(res => {
        if (!this.isNullOrUndefined(res.data())) {
            this.dataCustumer = res.data() as DataCustumerI; //Before customer_id 
            this.paymentService.verifySubscriptionStatus(this.dataCustumer.custumerId, this.dataCustumer.subscriptionId)
                .subscribe(res => {
                    this.card = res.card;
                    this.processing = false;
                    this.subscription = res;
                    const lastnumbers: string = this.subscription.card.card_number;
                    this.card_number = lastnumbers.substring(lastnumbers.length - 4);
                  });
        }
    }, error => { console.log(error); this.processing = false; });
  }

   isNullOrUndefined(value: any) {
    return value === null || value === undefined;
  }

}
