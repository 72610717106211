import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReservationI } from 'src/app/models/Reservation';
import { EarnPaymentService } from 'src/app/shared/services/earn-payments.service';
import { OrderService } from 'src/app/shared/services/order.service';
import {map} from "rxjs/operators";
import { AdService } from 'src/app/shared/services/ad.service';
import { AdI } from 'src/app/models/Ad';
@Component({
  selector: 'app-earning',
  templateUrl: './earning.component.html',
  styleUrls: ['./earning.component.css']
})
export class EarningComponent implements OnInit {


  subscription$: Subscription;
  
  payments = new Array();;
  
  total:number=0;
  ganancia_total:string;

  orders: ReservationI[];
  loading_more:boolean=false;
  loading:boolean=false;
  loading_details:boolean=false;

  reservation:ReservationI;
  ad: AdI;

  constructor(private adService:AdService,private orderService: OrderService, private earnPaymentS: EarnPaymentService) { }

  ngOnInit(): void {
        this.fetchPayments();
  } 

  OnDestroy(){
    this.subscription$.unsubscribe();
    
  }


  fetchPayments(){
    this.loading=true;
      this.subscription$ = this.earnPaymentS.getPayments()
      .subscribe((res:any)=>{
        res.map(payment => { 
          //Convert date iso to seconts
          payment.created_at =new Date(payment.created_at).getTime() / 1000;
           
          if(payment.paid==0) this.total += parseInt(payment.price_wd);
          payment.price = this.formatMoney(parseInt(payment.price));
          payment.price_wd = this.formatMoney(parseInt(payment.price_wd));
          
        })
        this.payments = res;
        this.loading=false;
        this.ganancia_total = this.formatMoney(this.total)
      },error => {
          this.loading=false;
      })
  }

  formatMoney(value){
    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value); /* $2,500.00 */
  }




  onOpenModal(payment){
    console.log(payment);
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.add('active');
    popup.classList.add('active');
    // this.client_name = client;
    this.fetchOrder(payment.service_id);
  }
  
  cerrarModal(){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.remove('active');
    popup.classList.remove('active');
    // this.reservation = null;
    // this.ad=null;
  }


  fetchOrder(reservationId) {
    this.loading_details = true;
    this.orderService.fetchOrder(reservationId)
        .onSnapshot((snapshot: any) => {
            this.reservation = snapshot.data() as ReservationI;
            if (this.reservation.paymentMethod === 'cash') this.reservation.paymentMethod = 'Efectivo';
            if (this.reservation.paymentMethod === 'card') this.reservation.paymentMethod = 'Tarjeta';
            this.fetchAd(this.reservation.adId);
        }, error => {  });
  }

  fetchAd(adId) {
    this.adService.getAdById(adId).subscribe((res) => {
        this.ad = res.data();
        this.loading_details=false;
    }, error => {
      this.loading_details=false;
    });
}

}
