import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { delay, retryWhen } from "rxjs/operators";
import { ContactaInformationI, ProviderUser } from "src/app/models/Provider";
import { ProfileService } from "src/app/shared/services/profile.service";
import { isNullOrUndefined } from "util";
import * as pattern from "../../../shared/constants/pattern-constants";
@Component({
  selector: "app-card-settings",
  templateUrl: "./card-settings.component.html",
})
export class CardSettingsComponent implements OnInit {
  
  subscriptionUser$: Subscription;
  processing:boolean = false;
  user: ProviderUser;
  createForm: UntypedFormGroup;
  message:string;
  loading:boolean=false;
  constructor(private profileService: ProfileService) {}

  ngOnInit(): void {
      this.createForm = this.formGroup();
      this.fetchingUserInformation();
  }

  fetchingUserInformation() {
    this.loading=true;
    this.subscriptionUser$ = this.profileService.getProfile()
        .pipe(retryWhen(errors => errors.pipe(delay(6000))))
        .subscribe(res => {
          
            this.loading = false;
            if (res.payload.data()) {
                this.user = res.payload.data() as ProviderUser;
                this.createForm.get('phoneNumber').setValue(this.user.phoneNumber);
                this.createForm.get('email').setValue(this.user.email);
                this.createForm.get('name').setValue(this.user.name);
                this.createForm.get('lastname').setValue(this.user.lastname);
                this.createForm.get('country').setValue("México");
                if(this.user.contactInformaction != null){
                  this.createForm.get('address').setValue(this.user.contactInformaction.address);
                  this.createForm.get('country').setValue(this.user.contactInformaction.country);
                  this.createForm.get('city').setValue(this.user.contactInformaction.city);
                  this.createForm.get('postal_code').setValue(this.user.contactInformaction.postal_code);
                  this.createForm.get('about').setValue(this.user.contactInformaction.about_me);
                } 
            }
        },error => {this.loading=false;});
  }

  onUpdateInformation(user:ProviderUser){
    var x = document.getElementById("snackbar");
   
    let contactInformaction : ContactaInformationI;
    const {address,country,city,postal_code,about} = this.createForm.value;
    contactInformaction = {
      address: address,
      country:country,
      city:city,
      postal_code:postal_code,
      about_me:about
    }
    this.processing = true;
    this.profileService.updatePersonalData(contactInformaction)
      .then(() =>{
        this.processing = false;
        this.showToast(x, 'Información actualizada');
      }).catch(error=>{
        this.processing = false;
        console.log(error);
      })

  }


  formGroup() {
    return new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        lastname: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        email: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.EMAIL_PATTERN)]),
        phoneNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.PHONE_PATTERN)]),
        address: new UntypedFormControl('', [Validators.required]),
        country: new UntypedFormControl('', [Validators.required]),
        city: new UntypedFormControl('', [Validators.required]),
        postal_code: new UntypedFormControl('', [Validators.required]),
        about: new UntypedFormControl('', [Validators.required]),
    });
  }

  showToast(x, message){
    this.message = message;
    x.className = "show";
    setTimeout(() => {
      x.className = "hide";
    }, 1200);
  }
  



}
