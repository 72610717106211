import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import Popper from "popper.js";
import firebase from 'firebase';
import { Router } from "@angular/router";

@Component({
  selector: "app-user-dropdown",
  templateUrl: "./user-dropdown.component.html",
})
export class UserDropdownComponent implements OnInit {
  dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  popper = document.createElement("div");

  public processing:boolean = false;

  constructor(public router: Router){
    
  }

  ngOnInit() {
    this.popper.innerHTML = `
    <div  class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1" style="min-width:12rem" #popoverDropdownRef>
    <a id="cerrar_sesion"  class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800">
      Salir
    </a>
  </div>
    `; 

    
  }
  toggleDropdown(event) {
    event.preventDefault();
    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
      this.destroyPopper();
    } else {
      this.dropdownPopoverShow = true;
      this.createPoppper();
    }
  }
  destroyPopper() {
    this.popper.parentNode.removeChild(this.popper);
  }
  createPoppper() {
    new Popper(this.btnDropdownRef.nativeElement, this.popper, {
      placement: "bottom-end",
    });
    this.btnDropdownRef.nativeElement.parentNode.insertBefore(
      this.popper,
      this.btnDropdownRef.nativeElement.nextSibling
    );
  }

  onLogout(){
    this.processing=true;
    firebase.auth().signOut().then(()=> {
      this.router.navigateByUrl('/');
    }).catch((error) => {
        this.processing=false;
    });
  }

  
  
}
