import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";
import { AuthGuard } from "./shared/guards/auth-guard.guard";
import { VerifyAccountProviderGuard } from "./shared/guards/verify-account-provider.guard";
import { LoginActiveGuard } from "./shared/guards/verify-active-sesion.guard";
import { VerifyStatusAccountPresentGuard } from "./shared/guards/verify-status-account-present.guard";
import { VerifySuspendedAccountProviderGuard } from "./shared/guards/verify-suspended-account.guard";
import { CardRegisteredsComponent } from "./views/admin/cards/card-registereds/card-registereds.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { PersonalComponent } from "./views/admin/docs/personal/personal.component";
import { BannerComponent } from "./views/admin/main/banner/banner.component";
import { EarningComponent } from "./views/admin/main/earning/earning.component";
import { PaymentComponent } from "./views/admin/main/payment/payment.component";
import { ReservationComponent } from "./views/admin/main/reservation/reservation.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { PanelComponent } from "./views/admin/panel/panel.component";
import { PlanComponent } from "./views/admin/plans/plan/plan.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { SubscriptionComponent } from "./views/admin/subscription/subscription.component";
import { TablesComponent } from "./views/admin/tables/tables.component";
import { UpdateSubscriptionComponent } from "./views/admin/update-subscription/update-subscription.component";
import { SuccessPaymentComponent } from "./views/admin/welcome/success-payment/success-payment.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";
import { PrivacyComponent } from "./views/legal/privacy/privacy.component";
import { TermsComponent } from "./views/legal/terms/terms.component";
import { MantenimientoComponent } from "./views/mantenimiento/mantenimiento.component";
import { PreregisterComponent } from "./views/preregister/preregister.component";
import { ProfileComponent } from "./views/profile/profile.component";
import { MainComponent } from "./views/seek/admin/main/main.component";
import { ProviderComponent } from "./views/seek/admin/provider/provider.component";
import { SoporteComponent } from "./views/soporte/soporte.component";


const routes: Routes = [
  // admin views
  {
    path: "admin",
    component: AdminComponent,
    children: [
      { path: "welcome", component: DashboardComponent,canActivate:[VerifyStatusAccountPresentGuard]},
      { path: "account", component: SettingsComponent },
      { path: "seek/admin", component: MainComponent },
      { path: "seek/admin/providers", component: ProviderComponent },
      { path: "update-subscription", component: UpdateSubscriptionComponent, canActivate:[VerifySuspendedAccountProviderGuard]},
      { path: "start-sub-pay", component: SubscriptionComponent },
      { path: "inicio", component: PanelComponent, canActivate:[VerifyAccountProviderGuard] },
      { path: "cards", component: CardRegisteredsComponent, canActivate:[VerifyAccountProviderGuard] },
      { path: "personal/docs", component: PersonalComponent},
      { path: "plans/inf", component: PlanComponent, canActivate:[VerifyAccountProviderGuard] },
      { path: "payment/inf", component: PaymentComponent, canActivate:[VerifyAccountProviderGuard] },
      { path: "earning/inf", component: EarningComponent, canActivate:[VerifyAccountProviderGuard] },
      { path: "reservation/inf", component: ReservationComponent, canActivate:[VerifyAccountProviderGuard] },
      { path: "banner/inf", component: BannerComponent, canActivate:[VerifyAccountProviderGuard] },
      { path: "skip/welcome", component: SuccessPaymentComponent, canActivate:[] }, //Falta Guard
      { path: "", redirectTo: "welcome", pathMatch: "full" },
    ],
    canActivate:[AuthGuard]
  },
  // auth views
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent,  canActivate:[LoginActiveGuard] },
      { path: "register", component: RegisterComponent, canActivate:[LoginActiveGuard]}, //Descomentar
      { path: "", redirectTo: "login", pathMatch: "full" },
    ],
  },
  // no layout views
  // { path: "profile", component: ProfileComponent },
  { path: "preregistro", component: PreregisterComponent },
  { path: "legal/privacidad", component: PrivacyComponent },
  { path: "legal/terminos", component: TermsComponent },
  { path: "working", component: MantenimientoComponent },
  { path: "soporte", component: SoporteComponent },
  { path: "", component: LandingComponent },
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
