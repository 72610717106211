import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as pattern from 'src/app/shared/constants/pattern-constants';
import { ContactService } from 'src/app/shared/services/contact.service';
import { FormPreregistroService } from 'src/app/shared/services/forms/form-preregistro.service';

@Component({
  selector: 'app-preregister',
  templateUrl: './preregister.component.html'
})
export class PreregisterComponent implements OnInit {

  createForm: UntypedFormGroup;
  loading:boolean = false;
  ocupations = [];
  otra_opcion:boolean=false;
  errors = [];

  constructor(private preregistroS:FormPreregistroService, private toast: ToastrService, private router: Router, private contactS: ContactService) { 
    this.createForm = this.preregistroS.formGroup(); 
    this.ocupations = preregistroS.fetchOcupations();
  }

  ngOnInit(): void {
    
  }

  register(){
    
    if (this.createForm.valid) {
      var accept:any = document.getElementById('checkedTerms');
      this.errors=[];
      if(accept.checked){
        var { ocupacion, name, email, phoneNumber, address, otra_ocupacion } = this.createForm.value;
        this.loading = true;
        
        //METODO 1 Firebase
        const STR = "Otro";
        
        if(ocupacion === STR) ocupacion = otra_ocupacion;
        
        this.contactS.sendInfo(name,ocupacion,email,phoneNumber,address)
          .subscribe(res =>{
            // console.log(res);
            this.loading=false;
            this.onOpenModal();
          }, error =>{
            // console.log(error);
            this.loading=false;
            this.toast.error("Ocurrio un error, intentelo más tarde.")
          });
      }else{
        this.toast.show("Debes aceptar los términos y condiciones  de uso de Seekmefy");
      }
    } else { 
      this.errors = this.preregistroS.getFormValidationErrors(this.createForm);
      this.toast.show("Verifica la información")
    }
  }

  onChange(e){
    const ocupacion = this.createForm.get('ocupacion').value;
    const OTHER = 'Otro';
    if(ocupacion === OTHER){
      this.otra_opcion = true;
    }else{
      this.otra_opcion = false;
    }
  }

  onOpenModal(){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.add('active');
    popup.classList.add('active');
  }
  
  cerrarModal(accept:boolean){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.remove('active');
    popup.classList.remove('active');
    if(accept) {
      this.router.navigateByUrl('/', { replaceUrl: true });
    }
  }



    
  
  
   




}
