import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProviderUser } from 'src/app/models/Provider';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { ProfileService } from 'src/app/shared/services/profile.service';

import * as pattern from "../../../../shared/constants/pattern-constants";
import firebase from "firebase/app"

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  createForm: UntypedFormGroup;
  provider: ProviderUser;
  processing: boolean = false;

  constructor( private firebaseAuth: AuthService,private profileS:ProfileService ,private router: Router, private toastS: ToastrService, private paymentS:PaymentService) { }

  ngOnInit(): void {
    this.createForm = this.formGroup();
     //Initializate Object Provider
     this.provider = {
      name: '',
      lastname: '',
      email: '',
      phoneNumber: '',
      organization: {
          name: '',
          description: '',
          adress: 'Añade una ubicación',
          type: 'Añade una Categoria  (construcción, despacho, limpieza, etc...)',
          logoUrl: 'https://firebasestorage.googleapis.com/v0/b/seekmyapp-test.appspot.com/o/DEF%2FLogo.png?alt=media&token=9f156d7b-62f4-4053-962e-552d0a1cf1f0',
          location: {
              latitud: '',
              longitude: ''
          },
          schedule: [],
          coverUrl: 'https://firebasestorage.googleapis.com/v0/b/seekmyapp-test.appspot.com/o/DEF%2Fdefault-seek-portada.png?alt=media&token=1e8ac4a8-a4b3-451c-a8db-5e66a290f52e',
          range: 0,
          specialty: '',
          allowCardPayments: false
      },
      createdAt: 0,
      modifiedAt: 0,
      isActive: true,
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/seekmyapp-test.appspot.com/o/DEF%2FFoto.png?alt=media&token=5d63818f-10c0-430d-acb6-2a3d00462a6b',
      fcmToken: '',
      tags: [],
      clabe: '',
      suspendedAccount:false,
      providerId:'',
      raiting : this.getRaintingModel()
      
      }

      //photoUrl = Foto de perfil del usuario
      //coverUrl == Portada
  }

  
getRaintingModel() {
  return  [
        {
            weight: 5,
            count: 0
        },
        {
            weight: 4,
            count: 0
        },
        {
            weight: 3,
            count: 0
        },
        {
            weight: 2,
            count: 0
        },
        {
            weight: 1,
            count: 0
        }
    ];
}

  public  onCreateUser() {

    
    if (this.createForm.valid) {
      this.assigningValues()
        const pass = this.createForm.get("pass").value;
        this.processing = true;

      
        this.firebaseAuth.createUser(this.provider.email, pass).then((res: any) => { //Crea usuario
          const providerId = res.user.uid;
          this.firebaseAuth.storeUser(this.provider, providerId).then((res: any) => {
            
            this.profileS.createDocProvider(providerId) //Creamos obj para guardar datos de identificacion
            .then(()=>{
                const fullName = this.provider.name + " " + this.provider.lastname;

                this.paymentS.storeCardAuthorization(
                  {
                    isActive: false,
                    custumerId: '',
                    card_id: '',
                    subscriptionId: '',
                    providerId: providerId
                  }
                )
                
                this.paymentS.storeCustomerOpenPay(providerId,fullName,this.provider.email)
                  .subscribe(res => {
                    console.log(res);
                    this.processing=false;
                     //Do soomething
                     firebase.auth().signOut()

                     this.firebaseAuth.login("lg-admin-usr@gmail.com","2022#luisg").then(() => {
                        this.createForm.reset();
                        this.toastS.success("Registro exitoso!");
                     })
                     .catch(err => {

                     })

                  },error => {
                    console.log(error);
                   //Show error
                  });
            });
          
          }).catch((error) => {
              this.processing = false;
              console.log(error);
          });

      }).catch((error) => {
          this.processing = false;
          if (error.code === 'auth/email-already-in-use') {
            this.toastS.error("Este correo ya esta en uso por otra cuenta");
          }
      })


    } else {  this.toastS.show("Verificatu información"); }
  }

  formGroup() {
    return new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        firstName: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        lastName: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        email: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.EMAIL_PATTERN)]),
        phoneNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.PHONE_PATTERN)]),
        pass: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.PASS_PATTERN)])
    });
  }

  assigningValues() {
    let createdAt = Math.round(new Date().getTime() / 1000);
    this.provider.createdAt = createdAt;
    this.provider.modifiedAt = createdAt;
    this.provider.name = this.createForm.get("name").value;
    this.provider.lastname =  this.createForm.get("firstName").value + " " + this.createForm.get("lastName").value;
    this.provider.email = this.createForm.get("email").value;
    this.provider.phoneNumber = this.createForm.get("phoneNumber").value;
    const provider_name = this.createForm.get("name").value + ' ' + this.createForm.get("firstName").value + " " + this.createForm.get("lastName").value;
    this.provider.tags.push(provider_name)
  }





  openProviders(){
    this.router.navigate(['admin/seek/admin/providers']);
  }



}
