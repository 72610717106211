import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { CustumerI, SubscriptionI, DataCustumerI, PaymentI } from 'src/app/models/Payment';
import { environment } from '../../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';

import { ChargeSubscriptionI } from 'src/app/models/Charge';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { uid } from 'uid';


@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    URI_BASE_CHARGE: string;

    constructor(private firestore: AngularFirestore, private httpClient: HttpClient) { }

    createCustumer(custumer: CustumerI): Observable<any> {
        const URI: string = environment.URI_BASE;
        return this.httpClient.post(URI + '/client', custumer);
    }

    //Metodo para crear customer =  Nuevo proveedor 

    // Returns current user UID
    get currentUserId(): string {
        return firebase.auth().currentUser.uid;
    }

    createProvider(custumer: CustumerI) {
        const URI: string = environment.URI_BASE;
        return this.httpClient.post(URI + '/business/new', custumer);
    }

    createSubscription(subscription: SubscriptionI): Observable<any> {
        const URI: string = environment.URI_BASE;
        return this.httpClient.post(URI + '/business', subscription);
    }
    
    verifySubscriptionStatus(customer_id: string, subscription_id: string): Observable<any> {
      
        const URI: string = environment.URI_BASE;
        return this.httpClient.post(URI + '/business/status', 
            { customer_id, subscription_id }
        );
    }

    //Cancelar suscripcion
    cancelSubscription(customer_id: string, subscription_id: string) {
        const URI_CANCELL = environment.URI_BASE;
        return this.httpClient.post(URI_CANCELL + '/business/cancel', { customer_id, subscription_id })
    }

    // Important
    storeCardAuthorization(customer: DataCustumerI) {
        const providerId = this.currentUserId;
        return new Promise((resolve, reject) => {
            this.firestore.collection('customers').doc(providerId).set(customer).then((res: any) => {
                resolve(res);
            }).catch((error) => {
                reject(error)
            })
        });
    }

    updateCustumerSubscription(subscriptioId: string) {
        const providerId = this.currentUserId;
        return this.firestore.collection('customers').doc(providerId).update(
            { "subscriptionId": subscriptioId }
        )
    }

    updateCustumerStatus() {
        const providerId = this.currentUserId;
        return this.firestore.collection('customers').doc(providerId).update(
            { "isActive": true }
        )
    }

    updateCustomerCancelled() {
        const providerId = this.currentUserId;
        return this.firestore.collection('customers').doc(providerId).update(
            { "isActive": false }
        )
    }

    getCustomer() {
        const providerId = this.currentUserId;
        return this.firestore.collection("customers").doc(providerId).get();
    }


    getProvider(){
        this.URI_BASE_CHARGE = environment.URI_BASE;
        const provider_id = this.currentUserId;
        
        return this.httpClient.post(this.URI_BASE_CHARGE + '/providers/view', { provider_id})
    }




    fetchCharges(customer_id: string): Observable<any> {
        this.URI_BASE_CHARGE = environment.URI_BASE;
        return this.httpClient.post(this.URI_BASE_CHARGE + '/client/charges', { customer_id })
    }

    ///PAIMENT MODULE BANNERS OPTON WITH CUSTOMER AND OTHER CARD
    payBannerOptionCharge(name: string, last_name: string, email: string, source_id: string, amount: number, description: string, device_session_id) {
        this.URI_BASE_CHARGE = environment.URI_BASE;
        return this.httpClient.post(this.URI_BASE_CHARGE + '/charge', { name, last_name, email, source_id, amount, description, device_session_id })
    }

    payBannerOptionChargeClient(source_id: string, amount: number, description: string, device_session_id: string, customer_id) {
        const URI_PAY_WITH_CUSTOMER = environment.URI_BASE;
        return this.httpClient.post(URI_PAY_WITH_CUSTOMER + '/charge', { source_id, amount, description, device_session_id, customer_id })
    }

    requestPayment(customer_id: string, clabe: string, fullName: string, quantity: number, description: string): Observable<any> {
        const URI: string = environment.URI_BASE;
        return this.httpClient.post(URI + '/business/money', { customer_id, clabe, fullName, quantity, description });
    }

    //:::::::::::::::::::::::  SAVE CHARGE DATA
    saveCharge(charge_id: string) {
        const providerId = this.currentUserId
        this.firestore.collection('charges')
            .add({ providerId: providerId, charge_id: charge_id })
    }

    storeSubscriptionCharges(chargeSuscription: ChargeSubscriptionI, current_period_number: string) {
        const providerId = this.currentUserId
        this.firestore.collection('providers')
            .doc(providerId)
            .collection("subscriptioncharges")
            .doc(current_period_number).set(chargeSuscription)
    }

    fetchSubscriptionCharges() { //ON update add limit  :::::::::::::::::::::::::::::: ! 
        const providerId = this.currentUserId
        return this.firestore.collection('providers')
            .doc(providerId)
            .collection('subscriptioncharges').snapshotChanges()
    }

    //REGISTRAR COBROS DE CUENTA PROVEEDOR ::::::::::::::::::::::::::::

    savePayment(payment: PaymentI) {
        const providerId = this.currentUserId
        return firebase.firestore().collection("providers")
            .doc(providerId)
            .collection('payments').add(payment)
    }

    getLastPayment() { // 
        const providerId = this.currentUserId
        return this.firestore.collection('providers')
            .doc(providerId)
            .collection("payments", ref => ref.orderBy("createdAt", "desc").limit(1)).snapshotChanges()
    }

    //Informacion para factuacion
    getSubscriptionCharge(period_number: string) {
        const providerId = this.currentUserId
        return this.firestore.collection('providers')
            .doc(providerId)
            .collection('subscriptioncharges')
            .doc(period_number).get()

    }

    //ACTUALIZAR  A FACTURADO 
    updateSubscriptionChargeToInvoiced(period_number: string) {
        const providerId = this.currentUserId
        return this.firestore.collection('providers')
            .doc(providerId)
            .collection('subscriptioncharges')
            .doc(period_number).update({ invoiced: true })
    }

    storeCustomerOpenPay(provider_id:string,name:string,email:string){
        const URI: string = environment.URI_BASE;
        return this.httpClient.post(URI+'/providers/add', 
                        {
                            provider_id:provider_id,
                            customer_id:null,
                            name:name,
                            email:email,
                            rfc:null,
                            clabe:null,
                            is_active:true
                        })
    }

    updateCustomerOpenPay(customer_id:string){
        const URI: string = environment.URI_BASE;
        const rfc = uid(16)+'rn';
        const providerId = this.currentUserId;
        
        return this.httpClient.put(URI+'/providers/update', 
                        {
                            provider_id:providerId, 
                            customer_id:customer_id,
                        })
    }




}