import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-plans',
  templateUrl: './landing-plans.component.html',
  styleUrls: ['./landing-plans.component.css']
})
export class LandingPlansComponent {

}
