import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import * as pattern from '../../constants/pattern-constants';


@Injectable({
    providedIn: 'root'
})
export class FormOpenPayService {
    
    constructor() { }

    formGroup() {
        return new UntypedFormGroup({
            number: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_AND_SPACES_PATTERN)]),
            cvv: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_AND_SPACES_PATTERN)]),
            month: new UntypedFormControl('',[Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
            year: new UntypedFormControl('',[Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
            country: new UntypedFormControl('',[Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
            state: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
            postal_code: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
            holder_name: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)])
        });
    }

}