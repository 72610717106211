import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { BannerI } from 'src/app/models/Banner';

import firebase from "firebase/app";
import { ProviderUser } from 'src/app/models/Provider';

@Injectable({
    providedIn: 'root'
})

export class ProviderService {

    constructor(private firestore: AngularFirestore) {}

    fetchProviders() {
        return this.firestore.collection('providers', ref =>
            ref.orderBy('createdAt',  "desc")
            .where('isActive', '==', true)
        ).get()
    }
    
    updateProvider(provider:ProviderUser) {
        return this.firestore.collection('providers').doc(provider.providerId).update(
            {
                "name" : provider.name,
                "lastname" : provider.lastname,
                "phoneNumber":provider.phoneNumber,
                "organization.name": provider.organization.name,
                "organization.adress": provider.organization.adress,
                "organization.description": provider.organization.description,
                "organization.location.latitud": provider.organization.location.latitud,
                "organization.location.longitude": provider.organization.location.longitude,
                "organization.type": provider.organization.type,
                "organization.specialty": provider.organization.specialty,
            }
        )
    }
    
    addRaiting( provider:ProviderUser ){
        const docRef = this.firestore.collection("providers").doc(provider.providerId);
        return docRef.update({
            raiting: [
                {
                    weight: 5,
                    count: 0
                },
                {
                    weight: 4,
                    count: 0
                },
                {
                    weight: 3,
                    count: 0
                },
                {
                    weight: 2,
                    count: 0
                },
                {
                    weight: 1,
                    count: 0
                }
            ]
        });
    }
}