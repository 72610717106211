import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AdI } from 'src/app/models/Ad';
import { ReservationI } from 'src/app/models/Reservation';
import { AdService } from 'src/app/shared/services/ad.service';
import { OrderService } from 'src/app/shared/services/order.service';


@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {

  subscriptionsFirstData$: Subscription;
  lastreference: number = 0;

  orders: ReservationI[];
  loading_more:boolean=false;
  loading:boolean=false;
  loading_details:boolean=false;

  reservation: ReservationI;
  ad: AdI;

  client_name:string;

  constructor(private adService:AdService,private orderService: OrderService, private toastS: ToastrService) {  }

  ngOnInit(): void {
    this.fetchOrders();
  }


  fetchOrders() {
    
    
    this.loading=true;
    this.subscriptionsFirstData$ = this.orderService.fetchOrdersCompleted().subscribe((res) => {
        this.orders = [];

        
        res.map(res => {

          this.loading = false;
            const data = res.payload.doc.data() as ReservationI;
            const reservationId = res.payload.doc.id;

            let order: ReservationI = {
                reservationId: reservationId,
                description: data.description,
                providerId: data.providerId,
                adId: data.adId,
                address: data.address,
                addressReference: data.addressReference,
                client: data.client,
                clientId: data.clientId,
                createdAt: data.createdAt,
                dateClient: data.dateClient,
                dateProvider: data.dateProvider,
                deliveryManId: data.deliveryManId,
                dispatching: data.dispatching,
                isActive: data.isActive,
                modifiedAt: data.modifiedAt,
                paid: data.paid,
                paymentMethod: data.paymentMethod,
                price: this.formatMoney(data.price),
                status: data.status,
            }
            if (order.paymentMethod === 'card') order.paymentMethod = 'Tarjeta';
            if (order.paymentMethod === 'cash') order.paymentMethod = 'Efectivo';

            this.orders.push(order)
            this.lastreference = data.createdAt;
                        
        });


        if(this.orders.length  == 0)this.loading=false;

    })
  }


  onLoadMore(lastReference){
    if (!this.isNullOrUndefined(this.subscriptionsFirstData$)) {
      this.subscriptionsFirstData$.unsubscribe();
    }

    this.loading_more=true;

    this.orderService.fetchNextOrdersCompleted(lastReference).subscribe((res) => {
      if (res.length === 0) {
        this.loading_more=false;
          this.toastS.show("No hay mas información.");
      }
      res.map(res => {
          
          const data = res.payload.doc.data() as ReservationI;
          const reservationId = res.payload.doc.id;
          this.loading_more=false;

         

          let order: ReservationI = {
              reservationId: reservationId,
              description: data.description,
              providerId: data.providerId,
              adId: data.adId,
              address: data.address,
              addressReference: data.addressReference,
              client: data.client,
              clientId: data.clientId,
              createdAt: data.createdAt,
              dateClient: data.dateClient,
              dateProvider: data.dateProvider,
              deliveryManId: data.deliveryManId,
              dispatching: data.dispatching,
              isActive: data.isActive,
              modifiedAt: data.modifiedAt,
              paid: data.paid,
              paymentMethod: data.paymentMethod,
              price: this.formatMoney(data.price),
              status: data.status,
          }

          if (order.paymentMethod === 'card') order.paymentMethod = 'Tarjeta';
          if (order.paymentMethod === 'cash') order.paymentMethod = 'Efectivo';
          this.orders.push(order);
          this.lastreference = data.createdAt;
      });

      

    })

  }
    

  isNullOrUndefined(value: any) {
    return value === null || value === undefined;
  }

  onOpenModal(reservationId,client){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.add('active');
    popup.classList.add('active');
    this.client_name = client;
    this.fetchOrder(reservationId);
  }
  
  cerrarModal(){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.remove('active');
    popup.classList.remove('active');
    this.reservation = null;
    this.ad=null;
  }

  fetchOrder(reservationId) {
    this.loading_details = true;
    this.orderService.fetchOrder(reservationId)
        .onSnapshot((snapshot: any) => {
            this.reservation = snapshot.data() as ReservationI;
            if (this.reservation.paymentMethod === 'cash') this.reservation.paymentMethod = 'Efectivo';
            if (this.reservation.paymentMethod === 'card') this.reservation.paymentMethod = 'Tarjeta';
            this.fetchAd(this.reservation.adId);
        }, error => {  });
  }



  fetchAd(adId) {
      this.adService.getAdById(adId).subscribe((res) => {
          this.ad = res.data();
          this.loading_details=false;
      }, error => {
        this.loading_details=false;
      });
  }


  public formatMoney(value){
    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value); /* $2,500.00 */
  }

}
