import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CategoryI } from 'src/app/models/Ad';
import { ProviderUser } from 'src/app/models/Provider';
import { AdCategoryService } from 'src/app/shared/services/AdCategory.service';
import { ProviderService } from 'src/app/shared/services/provider.service';
import { SearchEngineService } from 'src/app/shared/services/search.engine.service';
import * as pattern from "../../../../shared/constants/pattern-constants";

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css']
})
export class ProviderComponent implements OnInit {

  loading: boolean = false;

  providers: ProviderUser[];

  provider: ProviderUser;

  createForm: UntypedFormGroup;

  categorys: CategoryI[];

  companyCategory : CategoryI[];

  updating: boolean = false;

  sync: boolean = false;

  constructor(private providerService: ProviderService, private router: Router, private categoryS: AdCategoryService, private toastS: ToastrService, private searchEngineS: SearchEngineService) { }

  ngOnInit(): void {


    this.categorys = this.categoryS.getBussinesList();
    this.companyCategory = this.categoryS.categoryCompany();

    this.createForm = this.formGroup();

    this.loadProviders();
  }
  
  loadProviders(){
    this.loading=true;
    this.providerService.fetchProviders().subscribe(res => {
      this.providers = [];
      res.forEach(provider => {
        const providerUser = provider.data() as ProviderUser;
        this.providers.push(providerUser);
      });
      this.loading=false;
    },error=> this.loading=false)
  }

  onOpenModal(providerUser: ProviderUser){
    this.provider = providerUser;
    this.initValuesToFormGroup(providerUser);
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.add('active');
    popup.classList.add('active');
  }

  cerrarModal(){
    var overlay = document.getElementById('overlay'),
    popup = document.getElementById('popup');
    overlay.classList.remove('active');
    popup.classList.remove('active');
    this.initProviderObject();
  }
  
  formGroup() {
    return new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        lastname: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        email: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.EMAIL_PATTERN)]),
        phoneNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.PHONE_PATTERN)]),
        organizationName: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        organizationDescription: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        organizationAddress: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        organizationEspecialty: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        organizationType: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        organizationLat: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
        organizationLng: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)])
    });
  }

  initProviderObject(){
    this.provider = {
      name: '',
      lastname: '',
      email: '',
      phoneNumber: '',
      organization: {
          name: '',
          description: '',
          adress: '',
          type: '',
          logoUrl: '',
          location: {
              latitud: '',
              longitude: ''
          },
          schedule: [],
          coverUrl: '',
          range: 0,
          specialty: '',
          allowCardPayments: false
      },
      createdAt: 0,
      modifiedAt: 0,
      isActive: true,
      photoUrl: '',
      fcmToken: '',
      tags: [],
      clabe: '',
      suspendedAccount:false,
      providerId:''
      }
  }

  saveForm(providerUs: ProviderUser) {
    providerUs.name = this.createForm.get("name").value;
    providerUs.lastname = this.createForm.get("lastname").value;
    providerUs.organization.name = this.createForm.get("organizationName").value;
    providerUs.organization.description = this.createForm.get("organizationDescription").value;
    providerUs.organization.type = this.createForm.get("organizationType").value;
    providerUs.organization.specialty = this.createForm.get("organizationEspecialty").value;

    if(providerUs.organization.adress === 'Añade una ubicación')
    {
      providerUs.organization.adress = "Novias liz, Calle José María Morelos y Pavón, 20000 Aguascalientes, AGU, México";
    }

    if(providerUs.organization.location.latitud === '')
    {
      providerUs.organization.location.latitud = "21.881787";
    }

    if(providerUs.organization.location.longitude === '' || providerUs.organization.location.longitude === null)
    {
      providerUs.organization.location.longitude = "-102.294707";
    }
    
    this.updating = true;
    this.providerService.updateProvider(providerUs).then(response => {
        this.updating = false;
        this.cerrarModal();
        this.toastS.success("Se actualizo correctamente.");
    }, error => {
      this.updating = false;
      this.toastS.error("Algo paso, revise su conexión a internet.");
    })
  }

  
  initValuesToFormGroup(providerUs: ProviderUser){
    this.createForm.get("name").setValue(providerUs.name);
    this.createForm.get("lastname").setValue(providerUs.lastname);
    this.createForm.get("email").setValue(providerUs.email);
    this.createForm.get("phoneNumber").setValue(providerUs.phoneNumber);
    this.createForm.get("organizationName").setValue(providerUs.organization.name);
    this.createForm.get("organizationDescription").setValue(providerUs.organization.description);
    this.createForm.get("organizationAddress").setValue(providerUs.organization.adress);
    this.createForm.get("organizationEspecialty").setValue(providerUs.organization.specialty);
    this.createForm.get("organizationType").setValue(providerUs.organization.type);
    this.createForm.get("organizationLat").setValue(providerUs.organization.location.latitud);
    this.createForm.get("organizationLng").setValue(providerUs.organization.location.longitude);
  }

  // NA
  addRainting(providerUs: ProviderUser){
    this.providerService.addRaiting(providerUs).then(res => {
      console.log("Se añadio raiting a: " + providerUs.providerId);
    }, error => {
      console.log("Error: ");
    })
  }

  syncProviders() {
    this.sync = true;
    this.searchEngineS.syncProviders().subscribe((res) => {
      this.sync = false;
      this.toastS.success("Sincronizacion exitosa de firebase a servicio de busqueda.");
    }, error => {
      this.sync = false;
      this.toastS.error("Algo paso, contacte al administrador del sistema.");
    })
    
  }



}
