import { Injectable } from '@angular/core';
import { CategoryI } from 'src/app/models/Ad';


@Injectable({
    providedIn: 'root'
})
export class AdCategoryService {

    constructor() { }
    
    public listCategorySelect() {
        let categorys: CategoryI[] = [
            { value: "abarrotes", name: "Abarrotes" },
            { value: "comida_rapida", name: "Comida rapida" },
            { value: "oficina", name: "Oficina" },
            { value: "abogados", name: "Abogados" },
            { value: "restaurante", name: "Restaurante" },
            { value: "entretenimiento", name: "Entretenimiento" },
            { value: "farmacias", name: "Farmacias" },
            { value: "mariscos", name: "Mariscos" },
            { value: "estudio_fotografia", name: "Estudio & fotografía" },
            { value: "imprenta", name: "Imprenta" },
            { value: "fotografia", name: "Fotografía" },
            { value: "educacion_online", name: "Educación online" },
            { value: "estetica", name: "Estetica" },
            { value: "barber_shop", name: "Barber Shop" },
            { value: "contabilidad", name: "Contabilidad financiera" }
        ]

        return categorys;
    }

    public categoryCompany() {
        let categorys: CategoryI[] = [
            { value: "artes", name: "Artes" },
            { value: "cocina", name: "Cocina" },
            { value: "construccion", name: "Construcción" },
            { value: "electricidadyelectronica", name: "Electricidad y electrónica" },
            { value: "estetica_peluqueria", name: "Estética y peluqería" },
            { value: "finanzas_admin", name: "Finanzas y administración" },
            { value: "remodelacion", name: "Remodelación" },
            { value: "leyes", name: "Leyes" },
            { value: "limpieza", name: "Limpieza" },
            { value: "mantenimiento_auto", name: "Mantenimiento automotriz" },
            { value: "mantenimiento", name: "Mantenimiento" },
            { value: "remodelacion", name: "Remodelación" },
            { value: "salud_y_belleza", name: "Salud y belleza" },//+
            { value: "fotografia", name: "Fotografía" },//+
            { value: "salud_animal", name: "Salud animal" },
            { value: "bussines", name: "Negocios" },
            { value: "education", name: "Educación" },
            { value: "Otros", name: "Otro" },
        ]
        return categorys;

    }

    public getBussinesList() {
        let categorys: CategoryI[] = [
            { value: "albañil", name: "Albañil" },
            { value: "carpintero", name: "Carpintero" },
            { value: "cocinero", name: "Cocinero" },
            { value: "chef", name: "Chef" },
            { value: "dermatólogo ", name: "Dermatólogo" },
            { value: "decorador", name: "Decorador" },
            { value: "dentista", name: "Dentista" },
            { value: "estilista", name: "Estilista" },
            { value: "fisioterapeuta", name: "Fisioterapeuta" },
            { value: "fontanero", name: "Fontanero" },
            { value: "fotógrafo", name: "Fotógrafo" },
            { value: "fumigador", name: "Fumigador" },
            { value: "geriatra", name: "Geriatra" },
            { value: "guardia", name: "Guardia" },
            { value: "hojalatero", name: "Hojalatero" },
            { value: "herrero", name: "Herrero" },
            { value: "jardinero", name: "Jardinero" },
            { value: "maquillador", name: "Maquillador" },
            { value: "maestro", name: "Maestro" },
            { value: "mecánico", name: "Mecánico" },
            { value: "modelo", name: "Modelo" },
            { value: "pediatra", name: "Pediatra" },
            { value: "peluquero", name: "Peluquero" },
            { value: "pintor", name: "Pintor" },
            { value: "plomero", name: "Plomero" },
            { value: "técnico_en_electrónica", name: "Técnico en electrónica" },
            { value: "técnico_en_telefonía_y_cómputo", name: "Técnico en telefonía y cómputo" },
            { value: "técnico_en_sonido", name: "Técnico en sonido" },
            { value: "técnico_en_refrigeradores_y_lavadoras", name: "Técnico en refrigeradores y lavadoras" },
            { value: "veterinario", name: "Veterinario" },
            { value: "otro", name: "Otro" },
        ]
        return categorys;
    }

}
