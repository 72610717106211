import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import * as pattern from '../../constants/pattern-constants';

@Injectable({
    providedIn: 'root'
})
export class FormCreateBannerService {

    constructor() { }


    formGroup() {
        return new UntypedFormGroup({
            title: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
            description: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)]),
            link_banner: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)])
        });
    }

    getFormValidationErrors(createForm : UntypedFormGroup) {
        var errors = [];
        Object.keys(createForm.controls).forEach(key => {
        const controlErrors: ValidationErrors = createForm.get(key).errors;
          if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                  if(key == 'title') key = 'El campo title es requerido';
                  if(key == 'description') key = 'El description es requerido';
                  if(key == 'link_banner') key = 'El link del banner es requerido';
                  errors.push({name:key})
                  // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
              }
          }
        );
        return errors;
    }
    
    clear(createForm : UntypedFormGroup){
        createForm.get('title').setValue("");
        createForm.get('description').setValue("");
        createForm.get('linkUrl').setValue("");
    }

}