import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/shared/services/auth.service";
import * as pattern from "../../../shared/constants/pattern-constants";
import * as msg from "../../../shared/constants/messages-auth";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  
  createForm: UntypedFormGroup;
  processing: boolean = false;

  error_message:string='';

  constructor(
      private authService: AuthService,
      private router: Router
  ) {
      this.createForm = this.formGroup();
  }

  ngOnInit() { 
    var passInput = document.getElementById('password');
    passInput.addEventListener('keyup', (e) => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.onDoLogin();
      }
    });
  }


  onDoLogin() {
    var x = document.getElementById("snackbar");
      if (this.createForm.valid) {
          const email: string = this.createForm.get('_email').value;
          const password: string = this.createForm.get('_pass').value;
              this.processing = true;
              this.createForm.disable();
              this.authService.login(email, password).then((res: any) => {
                  if(res.user.email === "lg-admin-usr@gmail.com"){
                    this.router.navigateByUrl('/admin/seek/admin');
                  }else{
                    this.router.navigateByUrl('/admin/inicio');
                  }
              }).catch((error) => {
                  this.createForm.enable();
                  this.processing = false;
                  switch (error.code) {
                    case 'auth/wrong-password':
                      this.showToast(x, msg.PASS_OR_EMAIL_IVALID);
                      break;
                    case 'auth/user-not-found':
                      this.showToast(x, msg.EMAIL_IVALID);
                      break;
                    case 'auth/user-disabled':
                      this.showToast(x, msg.ACCOUNT_DISSABLED);
                      break;
                    case 'auth/too-many-requests':
                      this.showToast(x,  msg.MANY_REQUEST);
                      break;
                    default:
                      this.showToast(x,  'Verifique su información');
                      break;
                  }
              });
         
      } else { 
        this.showToast(x, 'Verifique sus datos');
      }
  }

  formGroup() {
      return new UntypedFormGroup({
          _email: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.EMAIL_PATTERN)]),
          _pass: new UntypedFormControl('', [Validators.required, Validators.pattern(pattern.NO_EMPTY_PATTERN)])
      });
  }
  get _email() { return this.createForm.get('_email') }
  get _pass() { return this.createForm.get('_pass') }


  showToast(x, messageError){
    this.error_message = messageError;
    x.className = "show";
    setTimeout(() => {
      x.className = "hide";
    }, 1200);
  }


}
