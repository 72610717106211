import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { map } from "rxjs/operators";
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private router: Router, private authS: AuthService) { }
  canActivate(): Observable<boolean> {
    return this.authS.userData$.pipe(
      map(user => {
        if (user) {
          return true;
        }
        this.router.navigateByUrl('/');
        return false;
      })
    );
  }
}
