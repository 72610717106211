import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanActivate, CanLoad, ResolveEnd, Router } from '@angular/router';
import { delay, map, retryWhen } from "rxjs/operators";
import { AuthService } from '../services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { ProfileService } from '../services/profile.service';
import { ProviderUser } from 'src/app/models/Provider';
import { data } from 'autoprefixer';
import { EmmiterService } from '../services/emmiter.service';

@Injectable({
  providedIn: 'root'
})
export class VerifyStatusAccountPresentGuard implements CanActivate {
  

  subscriptionUser$: Subscription;
  user: ProviderUser;

  constructor(private router: Router,private eventEmmitS: EmmiterService, private profileS: ProfileService) { }
  canActivate(): Promise<boolean> {
   
    return new Promise((resolve) => {
        this.profileS.getDataProvider()
        .then(res => {
            const user = res.data() as ProviderUser;
            if(!user.isActive){
                resolve(true);
            }else{
                this.router.navigateByUrl('/admin/inicio');
                resolve(false);
            }
        })
    })
  }
}