import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { BannerFI, BannerI } from 'src/app/models/Banner';

import firebase from "firebase/app";


@Injectable({
    providedIn: 'root'
})
export class BannerService {

    constructor(private firestore: AngularFirestore) { }

    createBanner(bannerId: string, banner: BannerI) {
        return new Promise((resolve, reject) => {
            this.firestore.collection('banners').doc(bannerId).set(banner).then((res: any) => {
                resolve(res);
            }).catch((error) => {
                reject(error)
            });
        });
    }

    get currentUser(){
        return firebase.auth().currentUser.uid;
    }
    
    fetchBanners() {
        const providerId = this.currentUser;
        return this.firestore.collection('banners', ref =>
            ref.where('providerId', '==', providerId)
        ).snapshotChanges()
    }

    fetchBannersOnline() {
        const providerId = this.currentUser;
        return this.firestore.collection('banners', ref =>
            ref.where('providerId', '==', providerId)
                .where('isActive', '==', true)
                .where('paid', '==', true)
        ).get()
    }

    removeBanner(bannerId: string) {
        return this.firestore.collection('banners').doc(bannerId).delete();
    }

    //SE EJECUTARA UNA VEZ SE PAGUE EL BANNER PROMOCIONAL
    updateBannerToPaid(bannerId: string) {
        this.firestore
            .collection('banners')
            .doc(bannerId).update({
                isActive: true,
                paid: true
            })
    }

    updateToExpiredBanner(bannerId: string) {
        this.firestore
            .collection('banners')
            .doc(bannerId).update({
                isActive: false,
            })
    }



    //Create
    save(banner: BannerFI) {
        const banner_id = this.firestore.createId();
        banner.banner_id = banner_id;
        return new Promise((resolve, reject) => {
            this.firestore.collection('banners').doc(banner_id).set(banner).then((res: any) => {
                resolve(res);
            }).catch((error) => {
                reject(error)
            });
        });
    }

    getBanners() {
        const providerId = this.currentUser;
        return this.firestore.collection('banners', ref =>
            ref.where('provider_id', '==', providerId)
                .where('is_active', '==', true)
        ).get()
    }

    deleteBanner(bannerId: string) {
        return new Promise((resolve, reject) => {
          this.firestore.collection('banners').doc(bannerId).delete()
            .then(() => {
                resolve('Documento eliminado exitosamente.');
            })
            .catch(error => {
              reject(error);
            });
        });
      }
      

}