import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import firebase from 'firebase';
import { ProviderUser } from "src/app/models/Provider";
import { AuthService } from "src/app/shared/services/auth.service";
import { EmmiterService } from "src/app/shared/services/emmiter.service";
import { ProfileService } from "src/app/shared/services/profile.service";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  collapseShow = "hidden";
  enable:boolean = false;
  public user: ProviderUser;
  constructor(public router: Router, private profileS: ProfileService,private authS: AuthService) {}

  ngOnInit() {
    this.profileS.getDataProvider()
        .then(res => {
            const user = res.data() as ProviderUser;
            this.user =  user;
            this.enable = user.isActive;
            // console.log(user);
        });
  }
  
  toggleCollapseShow(classes) {
    this.collapseShow = classes;
  }

  onLogout(){
    firebase.auth().signOut().then(()=> {
      this.router.navigateByUrl('/');
    }).catch((error) => {
        console.log(error);
    });
  }
}
