// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
      apiKey: "AIzaSyCxqsCo73zXzmpewaxpj8ZHLCyuXxKINqY",
      authDomain: "seekmyapp-test.firebaseapp.com",
      databaseURL: "https://seekmyapp-test.firebaseio.com",
      projectId: "seekmyapp-test",
      storageBucket: "seekmyapp-test.appspot.com",
      messagingSenderId: "385748537299",
      appId: "1:385748537299:web:3a88369474cb9793362926",
      measurementId: "G-CYPHB7GYBQ"
  },
  // URI_BASE: 'https://floating-eyrie-98781.herokuapp.com/api',
  //URI_BASE: 'https://www.conectasoldev.com/apiSeekCharge/api',
  URI_BASE :  'https://seekmefy.com/api',
  URI_BASE_CREATE_INVOICE: 'https://sheltered-scrubland-57373.herokuapp.com/api/factura',
  OPEN_PAY_ID: "md7dh5ekuuweyiset8qn",
  OPEN_PAY_API_KEY: "pk_e6b27b3dc8c042b8b945a5dabd13eb23",
  OPEN_PAY_SANDBOX_MODE: true,
  _AUTH_KEY: 'key=AAAAWdBnj9M:APA91bHp--hAk6_cnX4si6An8dPxp2lraJcPun8dGvh9RTLa1QQQZFXvJVZizFG79kQ_I8RsNz7MguT6j3g6HOgYmk0UHCn1XtJPu4sLU_zFfw55OQc7Tm6uoYnqEf51gQ_Lf8S3yQix'
};

/*|
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
